import { AlertProps, TableProps } from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { Geometry } from 'aws-sdk/clients/textract';
export type documentType = 'analysis' | 'questionnaire';

export interface documentTableItem {
  _id: string;
  documentFileName: string;
  processed: boolean;
  status: string;
  documentType: documentType;
  numberOfSentences: number;
  sentenceBatchesComplete: string[];
  sentenceBatches?: string[];
  published: boolean;
  folder: string;
  knowledge_base: boolean;
  chunks: { chunkNo: number; status: string }[];
  customerId: string;
  NoOfChunks: number;
  NumberOfPages: number;
  project?: string;
  createdAt: string;
  owner: string;
  permissions: any[];
  searchAble: boolean;
  statusEvents: any[];
  qaOrigin?: string;
  updatedAt?: string;
  __v?: number;
}

export interface FolderItem {
  folderName: string;
  folderDescription: string;
  project: string;
  customerId: string;
  createdAt: string;
  updatedAt: string;
  _id: string;
  permissions: any[];
}
interface baseSentence {
  _id: string;
  sentenceIndex: number;
  text: string;
  predicted_label_language: string;
  confidence_score_language: number;
  predicted_label_domain: string;
  confidence_score_domain: number;
  predicted_label_control: string;
  confidence_score_control: number;
  notes: INotes[];
  changedLanguage: boolean;
  changedControls: boolean;
  changedDomains: boolean;
  type: string;
  hidden?: boolean;
  filterWord?: string;
  burden: boolean;
  status_code: number;
  error: string | null;
  cleaned_text: string;
  parameters: boolean;
  parameter_arguments: string[];
  location: boolean;
  locations: any[];
  people_org: boolean;
  people_orgs: any[];
  Time_Information: boolean;
  Time_Entities: any[];
  Quantity_Information: boolean;
  Financial_Information: boolean;
  Financial_Amounts: any[];
  financial_arguments: any[];
  Dates: any[];
  Likely_Due_Date: string | null;
  Likely_Cost_Calculation: string | null;
  knowledge_base: boolean;
  knowledge_base_type: string;
  Words: any[];
  kbSentences: kbSentenceItem[];
  viewParameters?: any[];
  redlined: boolean;
  redlineLog: any[];
  All_Parameters: any[];
  __v?: any;
  createdAt?: any;
  updatedAt?: any;
  sentenceId: string;
}
export interface sentenceItem extends baseSentence {
  documentId: any;
  burden_attribution: string[];
  answer?: string;
  htmlAnswer?: string;
  qaSentences?: QandASentence[];
  Parent_Type: string;
  Parent_Id: string;
  Document_Id?: string;
  documentId_string: string;
  PageNumber: number;
  outline: outline[];
  penalties: boolean;
  penalties_score: number;
  termination: boolean;
  termination_score: number;
  chunkNo: number;
  starred?: boolean;
  date_arguments: string[];
  Percent_Information: boolean;
  mappedTargetSentences: kbSentenceItem[];
  mappedParentSentences: kbSentenceItem[];
  obligation_due_date: boolean;
  financial_due_date: boolean;
  service_level_req: boolean;
  netNew?: netNewItem[];
  checked?: boolean;
  Financial_aguments?: any[];
  burden_score?: number;
  Obligation_Linked?: boolean;
  customerId: string;
  contextId: string;
}

export interface ChatResponseSentence extends sentenceItem {
  [x: string]: any;
  Context: any;
  Document: any;
}
export interface netNewItem {
  fileName: string;
  documentId: string;
}

export interface outline {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

export interface kbSentenceItem extends baseSentence {
  score: number;
  documentId: string;
  knowledge_base_type: string;
  docName: string;
  similarity: number;
  Context: {
    text: string;
  };
  Document: {
    documentFileName: string;
    _id: string;
  };
  PageNumber: number;
}

export interface QandASentence extends sentenceItem {
  answer: string;
  htmlAnswer: string;
  qaSentences: QandASentence[];
  published: boolean;
}

export interface NetNewSentenceItem extends sentenceItem {
  score: number;
  documentId: string;
  knowledge_base_type: string;
  docName: string;
  similarity: number;
  Context: {
    text: string;
  };
  Document: {
    documentFileName: string;
    _id: string;
  };
  PageNumber: number;
}

export interface phraseItem extends sentenceItem {
  sentences: string[];
}

export interface IDomain {
  _id: string;
  domainCode: string;
  domainDescription: string;
  domainName: string;
}

// export interface IControl {
//   _id: string;
//   domainCode: string;
//   domainName: string;
//   controlName: string;
//   controlDescription: string;
//   controlDepartment: string;
//   controlOwners: string;
//   controlStatus: string;
// }

export interface DocumentContextModel {
  sentences: sentenceItem[];
  setSentences: (arg: sentenceItem[]) => void;
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
  controls: IControl[];
  domains: IDomain[];
  auth: any;
  getSentences: (arg: string) => void;
  selectedDomainOption: OptionDefinition;
  setSelectedDomainOption: (arg: OptionDefinition) => void;
  selectedControlOptions: OptionDefinition;
  setSelectedControlOptions: (arg: OptionDefinition) => void;
  createPhrase: (arg: sentenceItem[]) => void;
  currentDoc: documentTableItem;
  setCurrentDoc: (arg: documentTableItem) => void;
  currentDocId: string | null | undefined;
  setCurrentDocId: (arg: string) => void;
  selectType: TableProps.SelectionType;
  setSelectType: (arg: TableProps.SelectionType) => void;
  selectedItems: sentenceItem[] | QandASentence[];
  setSelectedItems: (arg: sentenceItem[] | QandASentence[]) => void;
  updateSentence: () => void;
  notesValue: string;
  setNotesValue: (arg: string) => void;
  breakPhraseApart: () => void;
  updateFocus: () => void;
  updateStarred: () => void;
  filesToUpload: fileUploadItem[];
  setFilesToUpload: (arg: fileUploadItem[]) => void;
  uploadProcessing: boolean;
  setUploadProcessing: (arg: boolean) => void;
  currentFolder: string;
  setCurrentFolder: (arg: string) => void;
  uploadFiles: (arg: string | null) => void;
  deleteDocument: (arg: documentTableItem) => Promise<any>;
  knowledgeBaseModal: boolean;
  setKnowledgeBaseModal: (arg: boolean) => void;
  updateRedline: () => void;
  knowledgeBaseSentences: kbSentenceItem[];
  selectedItemsKbResults: kbSentenceItem[];
  addKBsentences: (arg: kbSentenceItem[], close: boolean) => void;
  addDocToKnowledgeBase: (arg: string, arg2: string) => Promise<any>;
  kBulkUpload: (arg: any) => Promise<any>;
  getCurrentSentenceKB: (arg1: string, arg?: string[]) => void;
  kbLoading: boolean;
  setKbLoading: (arg: boolean) => void;
  setSelectedItemsKbResults: (arg: any[]) => void;
  refreshCurrentSentenceKB: () => Promise<any>;
  refreshKbLoading: boolean;
  removeAllKBReferences: () => void;
  updateSentenceLoading: boolean;
  getControls: () => void;
  getDomains: () => void;
  freeHandleAnalyze: (arg: string, kb?: OptionDefinition[]) => Promise<any>;
  freeformSelectedItemsKB: kbSentenceItem[];
  setFreeformeSelectedItemsKB: (arg: kbSentenceItem[]) => void;
  freeformSelectedItemsQA: QandASentence[];
  setFreeformeSelectedItemsQA: (arg: QandASentence[]) => void;
  kbSelectedItems: kbSentenceItem[];
  setKBSelectedItems: (arg: kbSentenceItem[]) => void;
  qaSelectedItems: QandASentence[];
  setQASelectedItems: (arg: QandASentence[]) => void;
  kbFilterOptions: OptionDefinition[];
  qaOrigin: string;
  setQAOrigin: (arg: string) => void;
  qaContact: any;
  setQAContact: (arg: any) => void;
  selectedItemsQAResults: QandASentence[];
  setSelectedItemsQAResults: (arg: QandASentence[]) => void;
  showQandQModal: boolean;
  setShowQandAModal: (arg: boolean) => void;
  getCurrentSentenceQA: () => Promise<any>;
  handleCloseQnadAModal: () => void;
  updateQASentence: (arg1: string, arg2: string) => void;
  kbModalFreeText: string;
  setKBModalFreeText: (arg: string) => void;
  handleSearch: () => void;
  publishQandADoc: () => void;
  resetDocumentState: () => void;
  qandaPassthroughAnalysis: (test: string) => Promise<any>;
  docViewFilter: filterEnum | null;
  setDocViewFilter: (arg: filterEnum | null) => void;
  controlFilter: string;
  setControlFilter: (arg: string) => void;
  savePinnedLoading: boolean;
  setSavePinnedLoading: (arg: boolean) => void;
  controlsNavigation: ControlsNavigation;
  setControlsNavigation: (arg: ControlsNavigation) => void;
  burdenAttFilter: string[];
  setBurdenAttFilter: (arg: string[]) => void;
  mainAlert: AlertInfo;
  setMainAlert: (arg: AlertInfo) => void;
  allDocuments: documentTableItem[];
  setAllDocuments: (arg: documentTableItem[]) => void;
  saveMappedItems: (arg: kbSentenceItem[], type: string) => void;
  mappedSaveLoading: boolean;
  setMappedSaveLoading: (arg: boolean) => void;
  getAggregatedViewSentences: (arg: string[]) => void;
  aggSentencesLoading: boolean;
  setAggSentencesLoading: (arg: boolean) => void;
  getAllDocs: () => void;
  filterButtonNav: ControlsNavigation;
  setFilterButtonNav: (arg: ControlsNavigation) => void;
  updateNetNew: (parentId: netNewItem) => Promise<any>;
  primaryDocument: netNewItem | null;
  setPrimaryDocument: (arg: netNewItem | null) => void;
  netNewSentencesForDoc: NetNewSentenceItem[];
  reportNameFromButton: string;
  setReportNameFromButton: (arg: string) => void;
  connectComponentOpen: boolean;
  setConnectComponentOpen: (arg: boolean) => void;
  CategorizeExpanded: boolean;
  setCategoriseExpanded: (arg: boolean) => void;
  connectActiveTabId: string;
  setConnectActiveTabId: (arg: string) => void;
}

export interface IProjectContext {
  mainProjects: Projects[];
  setMainProjects: (arg: Projects[]) => void;
  // getAllProjects: (arg: string) => void;
  selectedProjects: Projects[];
  setSelectedProjects: (arg: Projects[]) => void;
  folderName: string;
  setFolderName: (arg: string) => void;
  createNewFolder: () => void;
  createFolderLoading: boolean;
  updateFolder: (arg: string) => void;
  newFolderModalVisible: boolean;
  setNewFolderModalVisible: (arg: boolean) => void;
  deleteFolder: (arg: string) => void;
  selectedFolders: FolderItem[];
  setSelectedFolder: (arg: FolderItem[]) => void;
  projectIdContext: string;
  setProjectIdContext: (arg: string) => void;
  folderDescription: string;
  setFolderDescription: (arg: string) => void;
  allFolders: FolderItem[];
  setAllFolders: (arg: FolderItem[]) => void;
  currentProjectFolders: FolderItem[];
  setCurrentProjectFolders: (arg: FolderItem[]) => void;
  deleteLoadingFolder: boolean;
  setDeleteLoadingFolder: (arg: boolean) => void;
}

export interface fileUploadItem {
  sentences?: string[];
  name: string;
  uploaded: string;
  body?: ArrayBuffer;
  project?: string;
  folder?: string;
}

export interface IUserContext {
  user: IUser;
  token: string;
  isAdmin: boolean;
  isDeveloper: boolean;
  getSignIn: (arg1: string, arg2: string, arg3: boolean) => void;
  logout: () => void;
  // createAccount: (arg1: string, arg2: string, arg3: string) => void;
  auth: {
    headers: {
      Authorization: string;
    };
  };
  cognitoChallenge: boolean;
  passwordUserError: boolean;
  setToken: (arg: string) => void;
  isExpired: (arg: string) => boolean;
  users: IUser[];
  getUsers: () => void;
  setUsers: (arg: IUser[]) => void;
  updateTNC: () => Promise<any>;
  latestTNCAcknowledged: boolean;
  userChecksComplete: boolean;
  showNoticeModal: boolean;
  setShowNoticeModal: (arg: boolean) => void;
}

export interface IUser {
  username: string;
  customerId: string;
  isAdmin: boolean;
  email: string;
  name: string;
  sub: string;
  groups: string[];
  reseller: boolean;
}

export interface AlertType {
  statusIconAriaLabel: string;
  type: AlertProps.Type;
}

export enum DOCTYPE {
  questionnaire = 'questionnaire',
  analysis = 'analysis'
}

export interface TempSentence {
  _id: any;
  Text: string;
  Words: WordWithLine[];
  Parent_Type: string;
  Parent_Id: string;
  Document_Id: string;
  PageNumber: number;
}

export interface WordWithLine {
  BlockType: string;
  Confidence: number;
  Geometry: Geometry;
  Id: string;
  Page: number;
  Text: string;
  TextType: string;
  LineId: string;
}

export interface ControlsNavigation {
  [key: string]: number[];
}

export enum filterEnum {
  location = 'location',
  people_org = 'people_org',
  Likely_Due_Date = 'Likely_Due_Date',
  Time_Information = 'Time_Information',
  Quantity_Information = 'Quantity_Information',
  Financial_Information = 'Financial_Information',
  burden_attribution = 'burden_attribution',
  service = 'service',
  terminiation = 'termination',
  control = 'control',
  starred = 'starred',
  due_dates = 'due_dates',
  unclassified_burden = 'unclassified_burden',
  project_timelines = 'project_timelines',
  netNew = 'net_new'
}

export enum Controls {
  site_preparation = 'SITE PREPARATION, EXCAVATION & CLEARING', //
  structural_construction = 'STRUCTURAL FRAMEWORK AND BUILDING ENCLOSURE', //
  mechanical_electrical = 'BUILDING SYSTEMS INSTALLATION',
  speciality_construction = 'SPECIALTY CONSTRUCTION ACTIVITIES',
  equipment_machinery = 'EQUIPMENT & MACHINERY', //
  material_management = 'BUILDING MATERIAL MANAGEMENT', //
  project_management = 'PROJECT MANAGEMENT' //
}
export interface AlertInfo {
  showAlert: boolean;
  message: string;
  alertType: 'error' | 'success';
}

export const KBOptions = [
  {
    label: 'Rules & Regulations',
    value: 'Rules & Regulations'
  },
  {
    label: 'Governance Documents',
    value: 'Governance Documents'
  },
  {
    label: 'Executed Contracts',
    value: 'Executed Contracts'
  },
  {
    label: 'Questionnaires',
    value: 'Questionnaires'
  },
  {
    label: 'Emails',
    value: 'Emails'
  },
  {
    label: 'Reference',
    value: 'Reference'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];
// category_to_attribute = {
//   '(PC) PROJECT & CONTRACT MANAGEMENT': [
//     'PROJECT MANAGEMENT',
//     'CONSTRUCTION ADMINISTRATION'
//   ],
//   '(SP) SITE PREPARATION, STRUCTURAL CONSTRUCTION, FINISHING WORK': [
//     'SITE PREPARATION, EXCAVATION & CLEARING',
//     'STRUCTURAL FRAMEWORK AND BUILDING ENCLOSURE',
//     'BUILDING SYSTEMS INSTALLATION'
//   ],
//   '(MO) MACHINERY OPERATION & SPECIALITY CONSTRUCTION': [
//     'BUILDING MATERIAL MANAGEMENT',
//     'EQUIPMENT & MACHINERY',
//     'SPECIALTY CONSTRUCTION ACTIVITIES'
//   ]
// };

export enum UserRole {
  developer = 'developer'
}

export interface Projects {
  projectName: string;
  owner: string;
  projectDescription: string;
  folders: FolderItem[];
  customerId: string;
  projectType: string;
  _id: string;
  createdAt: string;
  permissions: any[];
}

export interface IControl {
  _id: string;
  controlDepartment: string;
  controlName: string;
  domainName: string;
  controlDescription: string;
  domainCode: string;
  controlOwners: string;
  controlStatus: string;
  controlButton: string;
}

export interface INotes {
  text: string;
  owner: string;
  date: number;
}

export interface IModalContext {
  shareModalVisible: boolean;
  setShareModalVisible: (arg: boolean) => void;
}
export interface ITourKey {
  [key: string]: boolean;
}
export interface IHelpContext {
  helpModalVisible: boolean;
  setHelpModalVisible: (arg: boolean) => void;
  tourType: string;
  setTourType: (arg: string) => void;
  showTourEvents: ITourKey;
  setShowTourEvents: (arg: ITourKey) => void;
  showTourStartPage: boolean;
  setShowTourStartPage: (arg: boolean) => void;
  viewed: ITourKey;
  setViewed: (arg: ITourKey) => void;
  showSearchComponents: ITourKey;
  setShowSearchComponents: (arg: ITourKey) => void;
  doNotShowAgain: boolean;
  setDoNotShowAgain: (arg: boolean) => void;
}
export interface TNCAcknowledge {
  version: string;
  date: number;
}
export interface UserM {
  _id: string;
  customerId: string;
  name: string;
  sub: string;
  email: string;
  isAdmin: false;
  tncAcknowledge: TNCAcknowledge[];
}

export interface AggregatedDocumentItem {
  [key: string]: {
    sentences: sentenceItem[];
    document: documentTableItem;
  };
}

export enum tourNameEmun {
  PROJECT = 'Project',
  FOLDER = 'Folder',
  DOCUMENT_VIEW = 'Document View',
  SEARCH = 'Search',
  CONNECT = 'Connect Information',
  QANDA = 'Question_and_Answer',
  QANDA_MODAL = 'Q and A Modal'
}

export interface ILandingPage {
  title: string;
  description: string;
  steps: { title: string; description: string }[];
}

export interface IChatSummary {
  [key: string]: sentenceItem[];
}

export enum SummaryNames {
  OBLIGATIONS_H1 = 'OBLIGATIONS & REQUIREMENTS',
  EQUIPMENT_H2 = 'Equipment & Materials',
  LABOR_H2 = 'Labor & Subcontracting',
  SERVICE_H2 = 'Service Levels',
  LOCATIONS_H2 = 'Locations',
  FINANCIAL_H1 = 'FINANCIAL DETAILS',
  FINANCIAL_H2 = 'FINANCIAL DUE DATES',
  WORK_H2 = 'WORK SCHEDULES',
  GENERAL_TIME_H2 = 'GENERAL TIMEFRAMES',
  NOTES_H1 = 'IMPORTANT NOTES'
}