import { Link } from '@cloudscape-design/components';
import { Children } from 'react';

interface ITourWithLinkProps {
  title?: string;
  text?: string;
  link?: string;
}
export const TourWithTextAndLink: React.FC<ITourWithLinkProps> = ({
  title,
  text,
  link
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          padding: '0 0',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <h3 style={{ fontWeight: 'bold' }}>{title}</h3>
        <p style={{ textAlign: 'left', marginTop: '5%' }}>{text}</p>
        {link ? (
          <Link external href={`${link}`} variant="primary">
            Show Me How
          </Link>
        ) : null}
      </div>
    </>
  );
};

interface ITourWithChildren {
  children: React.ReactNode;
}
export const TourWithChildren: React.FC<ITourWithChildren> = ({ children }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          padding: '0 0',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        {children}
      </div>
    </>
  );
};
