import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { UserContextProvider } from '../context/authContext';
import { ProtectedRoute } from './ProtectedRoute';
import { LoginPage } from './Components/signin_signup/LoginPage';
import { App } from '../App';
import { DocumentContextProvider } from '../context/documentContext';
import { ProjectContextProvider } from '../context/projectContext';
import { SignupPage } from './Components/signin_signup/SignupPage';
import { HelpContextProvider } from '../context/helpContext';

export const Routing = () => {
  return (
    <Router>
      <UserContextProvider>
        <HelpContextProvider>
          <ProjectContextProvider>
            <DocumentContextProvider>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route element={<ProtectedRoute />}>
                  <Route element={<App />} path="/app/*" />
                  {/* <Route path="/" element={<LoginPage />} /> */}
                  <Route
                    path="*"
                    element={<Navigate to="/app/landing" replace />}
                  />
                </Route>
              </Routes>
            </DocumentContextProvider>
          </ProjectContextProvider>
        </HelpContextProvider>
      </UserContextProvider>
    </Router>
  );
};
