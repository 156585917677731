import { IChatSummary, sentenceItem, SummaryNames } from '../models/interfaces';


const ControlsByDomain = {
  RES: [
    'Project Timeline',
    'Materials and Supplies',
    'Labor',
    'Shared Responsibilities',
    'Equipment',
    'Service Levels'
  ],
  SOW: [
    'Mechanical Details',
    'Structural Details',
    'Earthwork Excavation Grading and Trenching',
    'Erosion and Sediment Control',
    'Demolition',
    'Electrical Details',
    'Plumbing Details',
    'Civil Details',
    'Landscape Architecture Details',
    'Site Preparation',
    'Architectural Details',
    'Fire Protection Details'
  ],
  CON: [
    'Authority and Decision-Making',
    'Dispute Resolution',
    'Regulatory, Code Compliance',
    'Closure',
    'Representations',
    'Indemnification',
    'Miscellaneous Terms',
    'Termination',
    'Governing Laws',
    'Liquidated Damages',
    'General',
    'Taxes',
    'Insurance',
    'Modifications',
    'Safety Measures',
    'Financial Details',
    'Binding Effects',
    'Jurisdictions'
  ]
};

export const createChatSummary = (sentences: sentenceItem[]) => {
  const summary = sentences.reduce((acc: IChatSummary, curr: sentenceItem) => {
    if (
      curr.burden &&
      curr.predicted_label_domain === '(CON) CONTRACT OBLIGATIONS'
    ) {
      if (!acc[SummaryNames.OBLIGATIONS_H1]) {
        acc[SummaryNames.OBLIGATIONS_H1] = [curr];
      } else {
        acc[SummaryNames.OBLIGATIONS_H1].push(curr);
      }
    }
    if (
      curr.predicted_label_control === 'Financial Details' ||
      curr.Financial_Information
    ) {
      if (!acc[SummaryNames.FINANCIAL_H1]) {
        acc[SummaryNames.FINANCIAL_H1] = [curr];
      } else {
        acc[SummaryNames.FINANCIAL_H1].push(curr);
      }
    }
    if (
      curr.predicted_label_control === 'Materials and Supplies' ||
      curr.predicted_label_control === 'Equipment'
    ) {
      if (!acc[SummaryNames.EQUIPMENT_H2]) {
        acc[SummaryNames.EQUIPMENT_H2] = [curr];
      } else {
        acc[SummaryNames.EQUIPMENT_H2].push(curr);
      }
    }
    if (
      curr.predicted_label_control === 'Labor' ||
      curr.predicted_label_control === 'Shared Responsibilities' ||
      (curr.people_org && curr.burden)
    ) {
      if (!acc[SummaryNames.LABOR_H2]) {
        acc[SummaryNames.LABOR_H2] = [curr];
      } else {
        acc[SummaryNames.LABOR_H2].push(curr);
      }
    }
    if (curr.predicted_label_control === 'Service Levels') {
      if (!acc[SummaryNames.SERVICE_H2]) {
        acc[SummaryNames.SERVICE_H2] = [curr];
      } else {
        acc[SummaryNames.SERVICE_H2].push(curr);
      }
    }
    if (curr.location) {
      if (!acc[SummaryNames.LOCATIONS_H2]) {
        acc[SummaryNames.LOCATIONS_H2] = [curr];
      } else {
        acc[SummaryNames.LOCATIONS_H2].push(curr);
      }
    }
    if (curr.Financial_Information) {
      if (!acc[SummaryNames.FINANCIAL_H1]) {
        acc[SummaryNames.FINANCIAL_H1] = [curr];
      } else {
        acc[SummaryNames.FINANCIAL_H1].push(curr);
      }
    }
    if (
      curr.Financial_Information &&
      (curr.Time_Information ||
        curr.financial_due_date ||
        curr.obligation_due_date ||
        curr.date_arguments.length > 0 ||
        curr.Dates.length > 0 ||
        curr.Likely_Due_Date)
    ) {
      if (!acc[SummaryNames.FINANCIAL_H2]) {
        acc[SummaryNames.FINANCIAL_H2] = [curr];
      } else {
        acc[SummaryNames.FINANCIAL_H2].push(curr);
      }
    }

    // WORK SCHEDULES
    if (
      (curr.predicted_label_domain === '(RES) RESOURCE PLANNING' ||
        curr.predicted_label_domain === '(SOW) SCOPE OF WORK') &&
      !curr.Financial_Information &&
      (curr.Time_Information ||
        curr.obligation_due_date ||
        curr.date_arguments.length > 0 ||
        curr.Dates.length > 0 ||
        curr.Likely_Due_Date)
    ) {
      if (!acc[SummaryNames.WORK_H2]) {
        acc[SummaryNames.WORK_H2] = [curr];
      } else {
        acc[SummaryNames.WORK_H2].push(curr);
      }
    }
    // General Timeframes
    if (
      curr.predicted_label_domain === '(CON) CONTRACT OBLIGATIONS' &&
      !curr.Financial_Information &&
      (curr.Time_Information ||
        curr.obligation_due_date ||
        curr.date_arguments.length > 0 ||
        curr.Dates.length > 0 ||
        curr.Likely_Due_Date)
    ) {
      if (!acc[SummaryNames.WORK_H2]) {
        acc[SummaryNames.WORK_H2] = [curr];
      } else {
        acc[SummaryNames.WORK_H2].push(curr);
      }
    }

    if (curr?.notes?.length > 0) {
      if (!acc[SummaryNames.NOTES_H1]) {
        acc[SummaryNames.NOTES_H1] = [curr];
      } else {
        acc[SummaryNames.NOTES_H1].push(curr);
      }
    }
    return acc;
  }, {} as IChatSummary);
  // const keys = Object.keys(summary);
  // for (const key of keys) {
  //   const tempArray = Array.from(new Set(summary[key]));
  //   summary[key] = tempArray;
  // }

  const summaryKeys = Object.keys(summary);
  for (const key of summaryKeys) {
    const uniqueItems = new Map<string, sentenceItem>();
    for (const sentence of summary[key]) {
      if (!uniqueItems.has(sentence.text)) {
        uniqueItems.set(sentence.text, sentence);
      }
    }
    const noDuplicates = Array.from(uniqueItems.values());
    summary[key] = noDuplicates;
  }

  return summary;
};
