import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { IHelpContext, ITourKey, tourNameEmun } from '../models/interfaces';
import Cookies from 'js-cookie';

interface Props {
  children?: ReactNode;
}
export const HelpContext = createContext({} as IHelpContext);

export const HelpContextProvider: React.FC<Props> = (props) => {
  const [helpModalVisible, setHelpModalVisible] = useState<boolean>(false);
  const [tourType, setTourType] = useState<string>('');
  const [showTourEvents, setShowTourEvents] = useState<ITourKey>({});
  const [showTourStartPage, setShowTourStartPage] = useState<boolean>(false);
  const [viewed, setViewed] = useState<ITourKey>({});
  const [showSearchComponents, setShowSearchComponents] = useState<ITourKey>(
    {}
  );
  const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false);

  const location = useLocation();
  // staate
  useEffect(() => {
    const locationParts = location.pathname.split('/');
    console.log('locationParts', locationParts);
    if (locationParts[2] === 'base') {
      if (Cookies.get(tourNameEmun.DOCUMENT_VIEW)) return;
      if (!viewed[tourNameEmun.DOCUMENT_VIEW]) {
        setTimeout(() => {
          // setHelpModalVisible(true);
          setShowTourStartPage(true);
          setTourType(tourNameEmun.DOCUMENT_VIEW);
        }, 1000);
      } else {
        return;
      }
    }
    if (locationParts[2] === 'projects' && !(locationParts[4] === 'folder')) {
      if (Cookies.get(tourNameEmun.PROJECT)) return;
      if (!viewed[tourNameEmun.PROJECT]) {
        setTimeout(() => {
          setShowTourStartPage(true);
          setTourType(tourNameEmun.PROJECT);
        }, 500);
      } else {
        return;
      }
    }
    // if (locationParts[2] === 'projects') {
    //   if (Cookies.get(tourNameEmun.PROJECT)) return;
    //   if (!viewed[tourNameEmun.PROJECT]) {
    //     setTimeout(() => {
    //       setShowTourStartPage(true);
    //       setTourType(tourNameEmun.PROJECT);
    //     }, 500);
    //   } else {
    //     return;
    //   }
    // }
    if (locationParts[4] === 'folder') {
      console.log('in folder ++++++++++++');
      if (Cookies.get(tourNameEmun.FOLDER)) {
        console.log('cookeis folder', Cookies.get(tourNameEmun.FOLDER));
        return;
      }

      if (!viewed[tourNameEmun.FOLDER]) {
        console.log('not yet viewed folder');
        setTimeout(() => {
          setTourType(tourNameEmun.FOLDER);
          setShowTourStartPage(true);
        }, 1000);
      }
    }
    if (locationParts[2] === 'freeform') {
      if (Cookies.get(tourNameEmun.SEARCH)) {
        console.log('cookeis folder', Cookies.get(tourNameEmun.SEARCH));
        return;
      }
      if (!viewed[tourNameEmun.SEARCH]) {
        console.log('not yet viewed search');

        setShowSearchComponents({
          ...showSearchComponents,
          knowledgebase: true,
          qanda: true,
          all: true
        });
        setTimeout(() => {
          setTourType(tourNameEmun.SEARCH);
          setShowTourStartPage(true);
        }, 1000);
      }
    }
    if (locationParts[2] === 'questionnaire') {
      if (Cookies.get(tourNameEmun.QANDA)) {
        console.log('cookeis folder', Cookies.get(tourNameEmun.QANDA));
        return;
      }
      if (!viewed[tourNameEmun.QANDA]) {
        setTimeout(() => {
          setTourType(tourNameEmun.QANDA);
          setShowTourStartPage(true);
        }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const value = {
    helpModalVisible,
    setHelpModalVisible,
    tourType,
    setTourType,
    showTourEvents,
    setShowTourEvents,
    showTourStartPage,
    setShowTourStartPage,
    viewed,
    setViewed,
    showSearchComponents,
    setShowSearchComponents,
    doNotShowAgain,
    setDoNotShowAgain
  };

  return (
    <HelpContext.Provider value={value}>{props.children}</HelpContext.Provider>
  );
};
