import React from 'react';
import { Card, CardContent } from './lib/card';
import {
  FileText,
  FolderOpen,
  Users,
  BookOpen,
  ArrowRight,
  Search,
  GitMerge,
  CheckSquare,
  FileUp,
  Link,
  Activity
} from 'lucide-react';
import { Container } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

export const LandingPageNew = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="min-h-screen bg-gray-50">
        {/* Hero Section */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <div className="text-center">
              <h1 className="text-4xl font-bold text-gray-900 mb-6">
                Document Analysis & Compliance Made Simple
              </h1>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
                KORU analyzes your documents to automatically identify
                obligations, track requirements, and connect related information
                across your organization.
              </p>
              <div className="flex justify-center gap-4">
                <button
                  className="bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700"
                  onClick={() => {
                    navigate('/app/projects');
                  }}
                >
                  Get Started
                </button>
                <button
                  className="bg-white text-blue-600 px-6 py-3 rounded-md border border-blue-600 hover:bg-blue-50"
                  onClick={() => {
                    navigate('/app/video-overview');
                  }}
                >
                  Watch Demo
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Key Capabilities Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <h2 className="text-3xl font-bold text-center mb-12">
            What You Can Do with KORU
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <Card className="bg-white">
              <CardContent className="p-6">
                <div className="flex items-center gap-3 mb-4">
                  <FileText className="w-8 h-8 text-blue-600" />
                  <h3 className="text-xl font-semibold">Analyze Documents</h3>
                </div>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start gap-2">
                    <CheckSquare className="w-5 h-5 text-green-500 mt-1" />
                    <span>
                      Automatically identify key obligations and requirements
                    </span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckSquare className="w-5 h-5 text-green-500 mt-1" />
                    <span>Extract and track important deadlines</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckSquare className="w-5 h-5 text-green-500 mt-1" />
                    <span>Categorize information by type and priority</span>
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card className="bg-white">
              <CardContent className="p-6">
                <div className="flex items-center gap-3 mb-4">
                  <GitMerge className="w-8 h-8 text-blue-600" />
                  <h3 className="text-xl font-semibold">Connect Information</h3>
                </div>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start gap-2">
                    <CheckSquare className="w-5 h-5 text-green-500 mt-1" />
                    <span>Link related requirements across documents</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckSquare className="w-5 h-5 text-green-500 mt-1" />
                    <span>Maintain traceability as obligations evolve</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckSquare className="w-5 h-5 text-green-500 mt-1" />
                    <span>
                      Create a searchable web of connected information
                    </span>
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card className="bg-white">
              <CardContent className="p-6">
                <div className="flex items-center gap-3 mb-4">
                  <Users className="w-8 h-8 text-blue-600" />
                  <h3 className="text-xl font-semibold">Collaborate & Share</h3>
                </div>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start gap-2">
                    <CheckSquare className="w-5 h-5 text-green-500 mt-1" />
                    <span>Work together on document reviews</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckSquare className="w-5 h-5 text-green-500 mt-1" />
                    <span>Share insights and maintain review history</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckSquare className="w-5 h-5 text-green-500 mt-1" />
                    <span>Generate focused reports for stakeholders</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>
        </div>

        {/* Workflow Section */}
        <div className="bg-white py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12">
              How It Works
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <div className="text-center">
                <div className="flex justify-center mb-4">
                  <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
                    <FileUp className="w-8 h-8 text-blue-600" />
                  </div>
                </div>
                <h3 className="font-semibold mb-2">1. Upload Documents</h3>
                <p className="text-gray-600">
                  Upload your PDFs for automated analysis and obligation
                  tracking
                </p>
              </div>

              <div className="text-center">
                <div className="flex justify-center mb-4">
                  <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
                    <Activity className="w-8 h-8 text-blue-600" />
                  </div>
                </div>
                <h3 className="font-semibold mb-2">2. Analyze Content</h3>
                <p className="text-gray-600">
                  AI identifies obligations, requirements, and key information
                </p>
              </div>

              <div className="text-center">
                <div className="flex justify-center mb-4">
                  <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
                    <Link className="w-8 h-8 text-blue-600" />
                  </div>
                </div>
                <h3 className="font-semibold mb-2">3. Connect Information</h3>
                <p className="text-gray-600">
                  Link related requirements and build your knowledge network
                </p>
              </div>

              <div className="text-center">
                <div className="flex justify-center mb-4">
                  <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
                    <Users className="w-8 h-8 text-blue-600" />
                  </div>
                </div>
                <h3 className="font-semibold mb-2">4. Collaborate & Track</h3>
                <p className="text-gray-600">
                  Work together to review and manage obligations
                </p>
              </div>
            </div>

            <div className="mt-12 text-center">
              <button
                className="bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 inline-flex items-center gap-2"
                onClick={() => {
                  navigate('/app/projects');
                }}
              >
                Start Your Project <ArrowRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>

        {/* Knowledge Base Banner */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <Card className="bg-gray-50 border-gray-200">
            <CardContent className="p-8">
              <div className="flex flex-col md:flex-row items-center gap-8">
                <div className="flex-1">
                  <h2 className="text-2xl font-bold mb-4">
                    Build Your Knowledge Base
                  </h2>
                  <p className="text-gray-600 mb-6">
                    Create a searchable library of document expertise that grows
                    with each project. Maintain institutional knowledge and
                    ensure consistency across your organization.
                  </p>
                  <button
                    className="bg-white text-blue-600 px-6 py-2 rounded-md border border-blue-600 hover:bg-blue-50"
                    onClick={() => {
                      navigate('/app/landing/knowledgebase');
                    }}
                  >
                    Learn More
                  </button>
                </div>
                <div className="flex-1 flex justify-center">
                  <BookOpen className="w-32 h-32 text-blue-600" />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </Container>
  );
};
