import { useState, useEffect, useContext, useCallback } from 'react';
import {
  Table,
  Container,
  Button,
  SpaceBetween,
  Header,
  Box,
  Spinner,
  ProgressBar,
  Icon,
  Modal,
  Select,
  Pagination,
  ButtonDropdown,
  Multiselect
} from '@cloudscape-design/components';
import LoadingBar from '@cloudscape-design/chat-components/loading-bar';

// models
import {
  documentTableItem,
  FolderItem,
  KBOptions,
  Projects
} from '../models/interfaces';
import { useCollection } from '@cloudscape-design/collection-hooks';
// helpers
// dummy data
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DocumentContext } from '../context/documentContext';
import { UserContext } from '../context/authContext';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { MoveDocumentToProject } from './Components/projectMoveForm';
import { ProjectContext } from '../context/projectContext';
import { ModalContext } from '../context/modalContext';
// import { Share } from './Components/modelContents/share';
import { manageShare } from '../helpers/manageShareHelpers';
import { Op } from 'aws-sdk/clients/apigateway';

export const DocumentTableView = () => {
  const navigate = useNavigate();
  const {
    addDocToKnowledgeBase,
    resetDocumentState,
    allDocuments,
    setAllDocuments,
    getAllDocs
  } = useContext(DocumentContext);
  const {
    selectedProjects,
    setSelectedProjects,
    selectedFolders,
    currentProjectFolders,
    mainProjects
  } = useContext(ProjectContext);
  const { auth, token, users, user } = useContext(UserContext);
  // state
  const [selectedItemsLocal, setSelectedItemsLocal] = useState<
    documentTableItem[]
  >([]);
  // const [allDocuments, setAllDocuments] = useState<documentTableItem[]>([]);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [showDocTypeModal, setShowDocTypeModal] = useState<boolean>(false);
  const [docTypeValue, setDocTypeValue] = useState<OptionDefinition>({
    label: 'No Selection',
    value: 'NS'
  });
  const [kbLoading, setKBLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { deleteDocument } = useContext(DocumentContext);
  const [deleteCheckModla, setDeleteCheckModal] = useState<boolean>(false);
  const [addToKBModal, setAddToKBModal] = useState<boolean>(false);
  const [docsLoading, setDocLoading] = useState<boolean>(false);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [currentStorage, setCurrentStorage] = useState<number>(0);
  const [filteredByProject, setFilteredByProject] = useState<
    documentTableItem[]
  >([]);
  const [moveModal, setMoveModal] = useState<boolean>(false);
  const [folderList, setFolderList] = useState<OptionDefinition>({
    label: '',
    value: ''
  });
  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [shareModalVisible, setShareModalVisible] = useState<boolean>(false);
  const [selectedShare, setSelectedShare] = useState<OptionDefinition[]>([]);
  const [shareDocLoading, setShareDocLoading] = useState<boolean>(false);

  const [showReportButton, setShowReportButton] = useState<boolean>(false);

  // const getAllDocs = async () => {
  //   if (!auth) return;
  //   setDocLoading(true);

  //   const docs = await axios.post(
  //     `${process.env.REACT_APP_KORU_API}/document?type=all`,
  //     {},
  //     auth
  //   );
  //   if (docs.data.result) {
  //     setAllDocuments(docs.data.result);
  //   }
  //   setDocLoading(false);
  // };

  // const getDocContinous = (allDocumentsLocal: documentTableItem[]) => {
  //   const docList = allDocumentsLocal.map((ele) => ele.status);
  //   if (!docList.includes('Analyzing')) {
  //     return;
  //   } else {
  //     getAllDocs();
  //   }
  // };

  // useEffect(() => {
  //   getAllDocs();
  //   resetDocumentState();
  //   getStorageSize();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (selectedFolders.length === 0) {
      setFilteredByProject([]);
    } else {
      const folderIds = selectedFolders.map((ele) => ele._id);
      console.log('folder ids', folderIds);
      const filtered = allDocuments.filter((ele) =>
        folderIds.includes(ele?.folder)
      );
      setFilteredByProject(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDocuments, selectedFolders]);

  const getStorageSize = async () => {
    if (!auth.headers.Authorization) return;
    try {
      const storage = await axios.post(
        `${process.env.REACT_APP_KORU_API}/document?type=getGBSize`,
        {},
        auth
      );
      const convertToGB =
        (storage.data.result.totalSize / 1073741824 / 5) * 100;
      console.log('converted size', convertToGB);
      // const convertToGB = (10 * 1073741824) / 20;
      setCurrentStorage(convertToGB);
    } catch (e) {
      const error = e as Error;
      console.log('error in get storage', error.message);
    }
  };

  // useEffect(() => {
  //   getStorageSize();
  // }, []);
  useEffect(() => {
    if (token) {
      getStorageSize();
    }
  }, [token]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getDocContinous(allDocuments);
  //   }, 15000);
  //   return () => clearInterval(intervalId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [allDocuments]);

  const handleDelete = async () => {
    const tempSelectedItems = selectedItemsLocal;
    if (selectedItemsLocal.length < 1) return;
    setDeleting(true);
    for (let i = 0; i < tempSelectedItems.length; i++) {
      const result = await deleteDocument(tempSelectedItems[i]);
      if (result._id) {
        const tempAllDocs = allDocuments;
        const restDocs = tempAllDocs.filter(
          (ele) => ele._id !== tempSelectedItems[i]._id
        );
        setAllDocuments(restDocs);
      }
    }
    setSelectedItemsLocal([]);
    setDeleting(false);
    setDeleteCheckModal(false);
  };

  const handleAddToKB = async () => {
    setKBLoading(true);
    const result = await addDocToKnowledgeBase(
      selectedItemsLocal[0]._id,
      docTypeValue.value!
    );
    if (result.status === 200) {
      setDocTypeValue({ label: 'No Selection', value: 'NS' });
      setAddToKBModal(false);
      setSelectedItemsLocal([]);
      // getAllDocs();
    } else {
      setErrorMessage('Error adding to KB');
    }
  };

  const handleNavigateUpload = () => {
    switch (true) {
      case selectedProjects[0].projectType === 'workspace':
        navigate(
          `/app/upload?projectId=${selectedProjects[0]._id}&folder=${selectedFolders[0]._id}`
        );
        break;
      case selectedProjects[0].projectType === 'information_request':
        navigate(
          `/app/upload?projectId=${selectedProjects[0]._id}&type=questionnaire&folder=${selectedFolders[0]._id}`
        );
        break;
    }
  };

  const handleMoveProject = async () => {
    try {
      setMoveLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_KORU_API}/document?type=moveProject`,
        {
          _id: selectedItemsLocal[0]._id,
          newFolder: folderList.value
        },
        auth
      );
      getAllDocs();
      setMoveLoading(false);
      setMoveModal(false);
    } catch (e) {
      const error = e as Error;
      console.log('update error ', error.message);
    }
  };

  const handleActionButton = (e: any) => {
    console.log('e in action button', e.detail.id);
    switch (true) {
      case e.detail.id === 'moveFolder':
        setMoveModal(true);
        break;
      case e.detail.id === 'deleteSelected':
        setDeleteCheckModal(true);
        break;
      case e.detail.id === 'addKB':
        setAddToKBModal(true);
        break;
      case e.detail.id === 'reports':
        navigate(`/app/reports?documentId=${selectedItemsLocal[0]._id}`);
        break;
      case e.detail.id === 'addDocument':
        handleNavigateUpload();
        break;
    }
  };

  const updateShare = async () => {
    if (!selectedItems) return;
    try {
      setShareDocLoading(true);
      console.log('original', selectedItems[0].permissions);
      console.log('new', selectedShare);
      const getChanges = manageShare(
        selectedItems[0].permissions.map((ele) => {
          return { value: ele.sub, label: ele.name };
        }),
        selectedShare
      );
      const reqBody = {
        _id: selectedItems[0]._id,
        remove: getChanges.removedItems.map((ele) => {
          return {
            sub: ele.value,
            action: 'view',
            name: ele.label
          };
        }),
        add: getChanges.addedItems.map((ele) => {
          return {
            sub: ele.value,
            action: 'view',
            name: ele.label
          };
        })
      };
      const response = await axios.post(
        `${process.env.REACT_APP_KORU_API}/share?type=document`,
        reqBody,
        auth
      );
      console.log('reqBody', reqBody);
      // update the specific documents permissions
      const updatedItem = response.data.result;
      const tempDocs = allDocuments;
      const specificDocIndex = tempDocs.findIndex(
        (ele) => ele._id === updatedItem._id
      );
      if (specificDocIndex !== -1) {
        tempDocs[specificDocIndex].permissions = updatedItem.permissions;
      }
      setAllDocuments([...tempDocs]);

      // console.log('result', response.data);
      setShareDocLoading(false);
      setShareModalVisible(false);
      setSelectedShare([]);
      setSelectedItemsLocal([]);
    } catch (e) {
      const error = e as Error;
      console.log('Update error ', error.message);
      setShareDocLoading(false);
    }
  };
  const handleShareModalCancel = () => {
    setShareDocLoading(false);
    setShareModalVisible(false);
    setSelectedShare([]);
  };

  const showReportOption = () => {
    let valueToSet = false;
    if (selectedItemsLocal.length === 0 || mainProjects.length === 0)
      return false;
    if (selectedItems && selectedItemsLocal.length === 1) {
      const currentProjectType = mainProjects.filter(
        (ele) => ele._id === selectedItemsLocal[0].project!
      );

      if (currentProjectType[0]?.projectType === 'workspace') {
        valueToSet = true;
      }
    }
    setShowReportButton(valueToSet);
  };
  useEffect(() => {
    showReportOption();
  }, [selectedItemsLocal, mainProjects]);
  const emptyState = useCallback(() => {
    if (docsLoading && allDocuments.length === 0) {
      return <Spinner></Spinner>;
    } else {
      return (
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No resources</b>
            <Button
              onClick={() => handleNavigateUpload()}
              disabled={selectedFolders.length !== 1 ? true : false}
            >
              Upload document to start
            </Button>
          </SpaceBetween>
        </Box>
      );
    }
  }, [allDocuments, docsLoading, handleNavigateUpload, selectedFolders]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(filteredByProject, {
    filtering: {
      empty: <Box>Nothing</Box>,
      noMatch: <Box>No matches</Box>
    },
    pagination: { pageSize: 8 },
    sorting: {},
    selection: {}
  });

  const selectedDocPermissions = () => {
    const permissionsList = selectedItems![0]!.permissions;
    const tempOptionsList = permissionsList.map((member) => {
      return {
        label: member.name,
        value: member.sub
      };
    });
    setSelectedShare([...tempOptionsList]);
  };
  const { selectedItems } = collectionProps;
  useEffect(() => {
    const tempSelect = JSON.stringify(selectedItems);
    const parsedTempSelect = JSON.parse(tempSelect);
    setSelectedItemsLocal(parsedTempSelect);
    if (selectedItems && selectedItems.length === 1) {
      selectedDocPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, allDocuments]);

  return (
    <>
      <Container
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  iconName="share"
                  variant="icon"
                  disabled={!(selectedItems && selectedItems.length === 1)}
                  onClick={() => setShareModalVisible(true)}
                  data-tour-id="docShareButton"
                />
                <Button
                  disabled={!showReportButton}
                  onClick={() => {
                    navigate(
                      `/app/reports?documentId=${selectedItemsLocal[0]._id}`
                    );
                  }}
                  data-tour-id="reportsButton"
                >
                  Reports
                </Button>
                <Button
                  disabled={
                    !(
                      selectedItemsLocal.length === 1 &&
                      selectedItemsLocal[0].documentType === 'analysis'
                    )
                  }
                  onClick={() => setAddToKBModal(true)}
                  data-tour-id="addToKBButton"
                >
                  Add To Knowledgebase
                </Button>

                {/* {selectedItemsLocal.length > 0 && ( */}
                <Button
                  disabled={
                    !(selectedItemsLocal.length > 1) ||
                    selectedItemsLocal.length === 1
                  }
                  variant={selectedItemsLocal.length > 1 ? 'primary' : 'normal'}
                  onClick={() => {
                    if (selectedItemsLocal[0].documentType === 'analysis') {
                      navigate(
                        // `/app/base?documentId=${selectedItemsLocal[0]._id}&projectId=${selectedProjects[0]._id} `
                        `/app/base?documentId=${selectedItems?.map((ele) => ele._id)}&projectId=${selectedProjects[0]._id}&type=${selectedItems?.length === 1 ? 'single' : 'aggregated'}`
                      );
                    } else {
                      navigate(
                        `/app/questionnaire?documentId=${selectedItemsLocal[0]._id}&projectId=${selectedProjects[0]._id}`
                      );
                    }
                  }}
                  data-tour-id="viewMultipleDocumentButton"
                >
                  Aggregate View
                </Button>
                <Button
                  variant="primary"
                  disabled={!(selectedItemsLocal.length === 1)}
                  onClick={() => {
                    if (selectedItemsLocal[0].documentType === 'analysis') {
                      navigate(
                        // `/app/base?documentId=${selectedItemsLocal[0]._id}&projectId=${selectedProjects[0]._id} `
                        `/app/base?documentId=${selectedItems?.map((ele) => ele._id)}&projectId=${selectedProjects[0]._id}&type=${selectedItems?.length === 1 ? 'single' : 'aggregated'}`
                      );
                    } else {
                      navigate(
                        `/app/questionnaire?documentId=${selectedItemsLocal[0]._id}&projectId=${selectedProjects[0]._id}`
                      );
                    }
                  }}
                  data-tour-id="viewSingleDocumentButton"
                >
                  View Document
                </Button>
                <ButtonDropdown
                  data-tour-id="actionsButton"
                  items={[
                    {
                      text: 'Change Folder',
                      id: 'moveFolder',
                      disabled: selectedItems && selectedItems.length !== 1
                    },
                    {
                      text: 'Delete Document',
                      id: 'deleteSelected',
                      disabled: selectedItems && selectedItems.length !== 1
                    },
                    // {
                    //   text: 'Make Searchable',
                    //   id: 'addKB',
                    //   disabled: !showReportButton
                    // },
                    // {
                    //   text: 'Reports',
                    //   id: 'reports',
                    //   disabled: !showReportButton
                    // },
                    {
                      text: 'Add Document',
                      id: 'addDocument',
                      disabled: selectedFolders.length !== 1
                    }
                  ]}
                  onItemClick={(item) => handleActionButton(item)}
                >
                  Actions
                </ButtonDropdown>

                {/* )} */}
                {/* <Button onClick={() => handleNavigateUpload()}>
                  Add Document to Folder
                </Button> */}
                <ProgressBar
                  value={currentStorage}
                  additionalInfo="Storage Used"
                  // description="Progress bar description"
                  // label="Progress bar label"
                />
              </SpaceBetween>
            }
            description="To access aggregate view select more than one document."
          >
            Connected Documents
          </Header>
        }
      >
        {/* <div style={{ overflowY: 'scroll' }}> */}
        <Table
          // onSelectionChange={({ detail }) =>
          //   setSelectedItemsLocal(detail.selectedItemsLocal)
          // }

          {...collectionProps}
          contentDensity="compact"
          items={items}
          selectionType="multi"
          pagination={<Pagination {...paginationProps} />}
          columnDefinitions={[
            {
              id: 'title',
              header: 'Document Tile',
              cell: (item: documentTableItem) => item.documentFileName,
              sortingField: 'documentFileName',
              maxWidth: '400px'
            },
            {
              id: 'docStatus',
              header: 'Document Status',
              maxWidth: '200px',
              cell: (item: documentTableItem) => {
                if (item.status === 'analyzed') {
                  return (
                    <SpaceBetween size="l" direction="horizontal">
                      <Box>Analysis Complete</Box>
                      <ProgressBar status="success" />
                    </SpaceBetween>
                  );
                }
                if (item.status === 'Analyzing') {
                  return <LoadingBar variant="gen-ai" />;
                }
                if (item.status === 'Processing Error') {
                  return (
                    <SpaceBetween size="l" direction="horizontal">
                      <Box>Processing Error</Box>
                      <Icon name="status-negative"></Icon>
                    </SpaceBetween>
                  );
                }
                if (item.status === 'Published') {
                  return (
                    <SpaceBetween size="l" direction="horizontal">
                      <Box>Published</Box>
                      <ProgressBar status="success" />
                    </SpaceBetween>
                  );
                }
              },
              sortingField: 'status'
            },
            {
              id: 'docType',
              header: 'Document Type',
              cell: (item: documentTableItem) => item.documentType,
              sortingField: 'documentType',
              maxWidth: '200px'
            },
            {
              id: 'numPages',
              header: 'No Pages',
              cell: (item: documentTableItem) => item.NumberOfPages,
              maxWidth: '200px'
            },
            {
              id: 'createDate',
              header: 'Date Uploaded',
              cell: (item: documentTableItem) => item.createdAt.split('T')[0]
            },
            {
              id: 'permissions',
              header: 'Permissions',
              cell: (item: documentTableItem) =>
                `${item.permissions.map((ele) => ele.name).join(' , ')}`
            }
          ]}
          loadingText="Loading resources"
          empty={emptyState()}
          selectedItems={selectedItems}
        />
        {/* </div> */}
      </Container>
      <Modal
        visible={showDocTypeModal}
        header={<Header>Select Document Type</Header>}
        size="large"
        onDismiss={() => {
          setShowDocTypeModal(false);
        }}
      ></Modal>
      <Modal
        visible={deleteCheckModla}
        header={<Header>Delete Selected Document</Header>}
        onDismiss={() => setDeleteCheckModal(false)}
      >
        <SpaceBetween size={'s'} direction="horizontal">
          <Button onClick={() => setDeleteCheckModal(false)}>Cancel</Button>
          <Button onClick={() => handleDelete()} loading={deleting}>
            Delete
          </Button>
        </SpaceBetween>
      </Modal>
      <Modal
        visible={addToKBModal}
        header={<Header>Make the Document Searchable</Header>}
        onDismiss={() => setAddToKBModal(false)}
        footer={
          <SpaceBetween size={'s'} direction="horizontal">
            <Button onClick={() => setAddToKBModal(false)}>Cancel</Button>
            <Button onClick={() => handleAddToKB()} loading={deleting}>
              Add Document
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size={'s'} direction="vertical">
          <p>
            CAUTION: This will make the document searchable as well as viewable
            by anyone in your organization.
          </p>
          <Select
            selectedOption={docTypeValue}
            options={KBOptions}
            onChange={({ detail }) => setDocTypeValue(detail.selectedOption)}
            expandToViewport
          ></Select>
        </SpaceBetween>
      </Modal>
      <Modal
        visible={moveModal}
        header={<Header>Move Document</Header>}
        onDismiss={() => setMoveModal(false)}
        footer={
          <SpaceBetween size={'s'} direction="horizontal">
            <Button onClick={() => setMoveModal(false)}>Cancel</Button>
            <Button onClick={() => handleMoveProject()} loading={moveLoading}>
              Move Document
            </Button>
          </SpaceBetween>
        }
      >
        <MoveDocumentToProject
          folderList={folderList}
          setFolderList={setFolderList}
          filteredFolders={currentProjectFolders}
        />
      </Modal>
      <Modal
        size="large"
        visible={shareModalVisible}
        onDismiss={() => handleShareModalCancel()}
        header={'Share Document'}
        footer={
          <SpaceBetween size={'s'} direction="horizontal" alignItems="end">
            <Button onClick={() => handleShareModalCancel()}>Cancel</Button>
            <Button onClick={() => updateShare()} loading={shareDocLoading}>
              Update Permissions
            </Button>
          </SpaceBetween>
        }
      >
        <div
          style={{
            height: '40vh'
          }}
        >
          <Multiselect
            onChange={({ detail }) =>
              setSelectedShare([...detail.selectedOptions])
            }
            selectedOptions={selectedShare}
            options={users.map((ele) => {
              return {
                label: ele.name,
                value: ele.sub,
                disabled: ele.sub === user.sub
              };
            })}
          ></Multiselect>
        </div>
      </Modal>
    </>
  );
};
