import { LoadingBar } from '@cloudscape-design/chat-components';
import {
  SpaceBetween,
  LiveRegion,
  Cards,
  Button,
  Container,
  Box
} from '@cloudscape-design/components';
import { getKBDocLinkAndOpen } from '../../../helpers/getAndOpenLink';
import { IChatSummary, SummaryNames } from '../../../models/interfaces';

interface TopicSummaryProps {
  topicSummary: IChatSummary;
}

export const TopicSummary: React.FC<TopicSummaryProps> = ({ topicSummary }) => {
  return (
    <SpaceBetween size={'m'} direction="vertical">
      {Object.keys(topicSummary).length > 0 ? (
        <Container>
          {topicSummary[SummaryNames.OBLIGATIONS_H1]?.length > 0 ? (
            <div className="p-4">
              <h2 className="text-lg font-bold mb-2">
                {SummaryNames.OBLIGATIONS_H1}
              </h2>
              <div className="mb-6">
                {/* Bold heading */}
                {/* Indented bullet points */}
                <ul className="list-disc pl-8">
                  {topicSummary[SummaryNames.OBLIGATIONS_H1].map(
                    (values, index) => {
                      return (
                        <li key={index} className="mb-2">
                          {values.text}
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          ) : null}

          {topicSummary[SummaryNames.EQUIPMENT_H2].length > 0 ||
          topicSummary[SummaryNames.LABOR_H2].length > 0 ||
          topicSummary[SummaryNames.SERVICE_H2].length > 0 ||
          topicSummary[SummaryNames.LOCATIONS_H2].length > 0 ? (
            <div
              data-description="Outer div for reources planning"
              className="p-4"
            >
              <h2 className="text-lg font-bold mb-2">
                RESOURCE PLANNING & LOGISTICS
              </h2>
              {topicSummary[SummaryNames.EQUIPMENT_H2]?.length > 0 ? (
                <div className="mb-6 ml-[2%]">
                  <h3 className="text-md font-bold mb-2">
                    {SummaryNames.EQUIPMENT_H2}
                  </h3>
                  <ul className="list-disc pl-8">
                    {topicSummary[SummaryNames.EQUIPMENT_H2].map(
                      (ele, index) => {
                        return (
                          <li key={index} className="mb-2">
                            {ele.text}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              ) : null}
              {topicSummary[SummaryNames.LABOR_H2]?.length > 0 ? (
                <div className="mb-6 ml-[2%]">
                  <h3 className="text-md font-bold mb-2">
                    {SummaryNames.LABOR_H2}
                  </h3>
                  <ul className="list-disc pl-8">
                    {topicSummary[SummaryNames.LABOR_H2].map((ele, index) => {
                      return (
                        <li key={index} className="mb-2">
                          {ele.text}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              {topicSummary[SummaryNames.SERVICE_H2]?.length > 0 ? (
                <div className="mb-6 ml-[2%]">
                  <h3 className="text-md font-bold mb-2">
                    {SummaryNames.SERVICE_H2}
                  </h3>
                  <ul className="list-disc pl-8">
                    {topicSummary[SummaryNames.SERVICE_H2].map((ele, index) => {
                      return (
                        <li key={index} className="mb-2">
                          {ele.text}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              {topicSummary[SummaryNames.LOCATIONS_H2]?.length > 0 ? (
                <div className="mb-6 ml-[2%]">
                  <h3 className="text-md font-bold mb-2">
                    {SummaryNames.LOCATIONS_H2}
                  </h3>
                  <ul className="list-disc pl-8">
                    {topicSummary[SummaryNames.LOCATIONS_H2].map(
                      (ele, index) => {
                        return (
                          <li key={index} className="mb-2">
                            {ele.text}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              ) : null}
            </div>
          ) : null}
          {/* Financial Details */}
          {topicSummary[SummaryNames.FINANCIAL_H1]?.length > 0 ? (
            <div className="p-4">
              <h2 className="text-lg font-bold mb-2">
                {SummaryNames.FINANCIAL_H1}
              </h2>
              <div className="mb-6">
                {/* Bold heading */}
                {/* Indented bullet points */}
                <ul className="list-disc pl-8">
                  {topicSummary[SummaryNames.FINANCIAL_H1].map(
                    (values, index) => {
                      return (
                        <li key={index} className="mb-2">
                          {values.text}
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          ) : null}
          {topicSummary[SummaryNames.FINANCIAL_H2]?.length > 0 ||
          topicSummary[SummaryNames.WORK_H2]?.length > 0 ||
          topicSummary[SummaryNames.GENERAL_TIME_H2]?.length > 0 ? (
            <div
              data-description="Outer div for reources planning"
              className="p-4"
            >
              <h2 className="text-lg font-bold mb-2">TIMEFRAMES</h2>
              {topicSummary[SummaryNames.FINANCIAL_H2]?.length > 0 ? (
                <div className="mb-6 ml-[2%]">
                  <h3 className="text-md font-bold mb-2">
                    {SummaryNames.FINANCIAL_H2}
                  </h3>
                  <ul className="list-disc pl-8">
                    {topicSummary[SummaryNames.FINANCIAL_H2].map(
                      (ele, index) => {
                        return (
                          <li key={index} className="mb-2">
                            {ele.text}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              ) : null}
              {topicSummary[SummaryNames.WORK_H2]?.length > 0 ? (
                <div className="mb-6 ml-[2%]">
                  <h3 className="text-md font-bold mb-2">
                    {SummaryNames.WORK_H2}
                  </h3>
                  <ul className="list-disc pl-8">
                    {topicSummary[SummaryNames.WORK_H2].map((ele, index) => {
                      return (
                        <li key={index} className="mb-2">
                          {ele.text}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              {topicSummary[SummaryNames.GENERAL_TIME_H2]?.length > 0 ? (
                <div className="mb-6 ml-[2%]">
                  <h3 className="text-md font-bold mb-2">
                    {SummaryNames.GENERAL_TIME_H2}
                  </h3>
                  <ul className="list-disc pl-8">
                    {topicSummary[SummaryNames.GENERAL_TIME_H2].map(
                      (ele, index) => {
                        return (
                          <li key={index} className="mb-2">
                            {ele.text}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              ) : null}
            </div>
          ) : null}
          {/* Notes */}
          {topicSummary[SummaryNames.NOTES_H1]?.length > 0 ? (
            <div className="p-4">
              <h2 className="text-lg font-bold mb-2">
                {SummaryNames.NOTES_H1}
              </h2>
              <div className="mb-6">
                {/* Bold heading */}
                {/* Indented bullet points */}
                <ul className="list-disc pl-8">
                  {topicSummary[SummaryNames.NOTES_H1].map((values, index) => {
                    return (
                      <>
                        <li key={index} className="mb-2">
                          {values.text}
                        </li>
                        <li key={index} className="mb-2">
                          {values?.notes.join(',')}
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
        </Container>
      ) : (
        <LiveRegion>
          <Box margin={{ bottom: 'xs', left: 'l' }} color="text-body-secondary">
            Generating a response
          </Box>
          <LoadingBar variant="gen-ai" />
        </LiveRegion>
      )}
    </SpaceBetween>
  );
};
