import React, { useContext, useEffect, useRef, useState } from 'react';
import Joyride, { Step, CallBackProps, StoreHelpers } from 'react-joyride';
import { DocumentContext } from '../../../context/documentContext';
import { HelpContext } from '../../../context/helpContext';
import { steps } from './helpTours';
import { tourNameEmun } from '../../../models/interfaces';

export const HelpStepThrough = () => {
  const {
    setSelectedItems,
    setConnectComponentOpen,
    setCategoriseExpanded,
    setConnectActiveTabId
  } = useContext(DocumentContext);
  const {
    helpModalVisible,
    setHelpModalVisible,
    tourType,
    setTourType,
    setShowTourEvents,
    setShowSearchComponents
  } = useContext(HelpContext);
  const helpersRef = useRef<StoreHelpers | null>(null);
  const [localSteps, setLocalSteps] = useState<Step[]>([]);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const handleJoyrideCallback = (data: CallBackProps) => {
    console.log('callback data', data);
    if (data.status === 'running') {
      setStepIndex(data.index);
    }

    switch (true) {
      case data.lifecycle === 'ready' && tourType === tourNameEmun.CONNECT:
        setConnectComponentOpen(true);
        setConnectActiveTabId(localSteps[data.index].data);
        break;
      case data.action === 'reset':
        setConnectComponentOpen(false);
        setSelectedItems([]);
        setCategoriseExpanded(false);
        setHelpModalVisible(false);
        setTourType('');
        setShowTourEvents({});
        setShowSearchComponents({});
        break;
    }
  };
  const startTour = () => {
    if (!tourType) {
      return;
    }
    if (helpersRef.current) {
      setLocalSteps(steps[tourType]);
      console.log('current ref', helpersRef.current);
      helpersRef.current.open();
    }
  };

  useEffect(() => {
    startTour();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpModalVisible]);

  return (
    // <></>
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      scrollOffset={20}
      scrollToFirstStep
      // run={helpModalVisible}
      // stepIndex={stepIndex}
      disableOverlay={tourType === tourNameEmun.QANDA_MODAL ? true : false}
      getHelpers={(helpers) => {
        helpersRef.current = helpers; // Store the helpers reference
      }}
      // showProgress
      spotlightClicks
      showSkipButton
      steps={localSteps}
      styles={{
        options: {
          zIndex: 10000,
          arrowColor: '#2C6CC0',
          backgroundColor: '#FFFFFF',
          overlayColor: 'rgba(25, 20, 17, 0.4)',
          primaryColor: '#2C6CC0',
          textColor: '#000000',
          width: 400
        }
      }}
    />
  );
};
