import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Grid,
  Header,
  Modal,
  Multiselect,
  SpaceBetween,
  TextContent,
  Textarea
} from '@cloudscape-design/components';
import { QandAResponses } from './qandaResponses';
import { useContext, useEffect, useRef, useState } from 'react';
import { DocumentContext } from '../../context/documentContext';
import { KnowledgeBaseModal } from './KnowledgeBaseModal';
import PopoverWrapper from './popoverWrapper';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  QandASentence,
  KBOptions,
  tourNameEmun
} from '../../models/interfaces';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
// import { KBItemTypes } from '../../models/documentTableModel';
import { Notes } from './workWithDocument/components/notes';
import { HelpContext } from '../../context/helpContext';
interface QandAModalProps {
  showQandQModal: boolean;
  handleCloseQnadAModal: () => void;
}

export const QandAModal = (props: QandAModalProps) => {
  const { showQandQModal, handleCloseQnadAModal } = props;
  const {
    getCurrentSentenceKB,
    selectedItemsKbResults,
    selectedItems,
    notesValue,
    setNotesValue,
    currentDoc,
    updateQASentence
  } = useContext(DocumentContext);
  const { setHelpModalVisible, setTourType, viewed, setViewed } =
    useContext(HelpContext);
  const [localAnswerString, setLocalAnswerString] = useState<string>('');
  const [qaOpen, setQAOpen] = useState<boolean>(true);
  const [kbOpen, setKBOpen] = useState<boolean>(false);
  const [selectedTypes, setSelectedTypes] = useState<OptionDefinition[]>([]);

  const reactQuillRef = useRef<ReactQuill>(null);

  const getText = () => {
    if (reactQuillRef.current) {
      const editor = reactQuillRef.current.getEditor();
      console.log('editor text', editor.getText());
      return editor.getText();
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (selectedItems[0]) {
      const current = selectedItems[0] as unknown as QandASentence;
      console.log('selected current item ', current.answer);
      setLocalAnswerString(current.htmlAnswer);
      setNotesValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleSave = async () => {
    const textOnly = getText();
    updateQASentence(textOnly!, localAnswerString);
    handleCloseQnadAModal();
  };

  const handleAddToAnswer = (text: string) => {
    const tempString = localAnswerString + ' ' + text;
    setLocalAnswerString(tempString);
  };

  const kbQuantity = () => {
    if (selectedItems[0].kbSentences.length > 0) {
      return (
        selectedItems[0].kbSentences.length + selectedItemsKbResults.length
      );
    } else {
      return selectedItemsKbResults.length;
    }
  };

  const handleResearch = () => {
    if (selectedTypes.length > 0) {
      const typesToSend = selectedTypes.map((ele) => ele.label!);
      getCurrentSentenceKB('qaSentence', typesToSend);
    } else {
      getCurrentSentenceKB('qaSentence');
    }
  };
  useEffect(() => {
    if (showQandQModal) {
      if (viewed[tourNameEmun.QANDA]) {
        return;
      } else {
        setHelpModalVisible(true);
        setViewed({ ...viewed, [tourNameEmun.QANDA]: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showQandQModal]);

  return (
    <Modal
      data-tour-id="qandaModal"
      header={
        <Header actions={<SpaceBetween size={'l'}></SpaceBetween>}>
          <SpaceBetween
            size={'xs'}
            direction="horizontal"
            data-tour-id="questionHeader"
          >
            <TextContent>
              <h1>Question</h1>
            </TextContent>
            <p
              style={{
                fontStyle: 'italic',
                fontSize: '1.3em',
                backgroundColor: 'yellow'
              }}
            >
              {selectedItems[0]?.text}
            </p>
          </SpaceBetween>
        </Header>
      }
      visible={showQandQModal}
      onDismiss={() => handleCloseQnadAModal()}
      size="max"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setHelpModalVisible(true);
                setTourType(tourNameEmun.QANDA_MODAL);
              }}
            >
              Tour
            </Button>
            <PopoverWrapper
              popoverContent={'Save answer and notes'}
              sideToShow="LEFT"
              aboveBelow="TOP"
            >
              <Button
                variant="primary"
                onClick={() => handleSave()}
                disabled={currentDoc.status === 'Published'}
                data-tour-id="qandaSave"
              >
                Save
              </Button>
            </PopoverWrapper>
          </SpaceBetween>
        </Box>
      }
    >
      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
        <div
          style={{
            height: '65vh',
            overflowY: 'scroll'
          }}
          // data-tour-id="qandaSearchResults"
        >
          <Container>
            <SpaceBetween size={'s'}>
              <QandAResponses
                
                setLocalAnswerString={setLocalAnswerString}
                localAnswerString={localAnswerString}
                handleAddToAnswer={handleAddToAnswer}
                qaOpen={qaOpen}
                setQAOpen={setQAOpen}
              />
              {selectedItemsKbResults?.length > 0 ||
              selectedItems[0]?.kbSentences?.length > 0 ? (
                <ExpandableSection
                  variant="container"
                  headerText={`Knowledge Map - (${kbQuantity()})`}
                  expanded={kbOpen}
                  onChange={() => setKBOpen(!kbOpen)}
                >
                  <KnowledgeBaseModal
                    parentType="QandA"
                    filterType={[]}
                    setLocalAnswerString={setLocalAnswerString}
                    localAnswerString={localAnswerString}
                    handleAddToAnswer={handleAddToAnswer}
                  />
                </ExpandableSection>
              ) : null}
            </SpaceBetween>
          </Container>
        </div>
        <div style={{ height: '80vh', overflow: 'scroll' }}>
          <Container header={<Header>Build Answer</Header>}>
            <SpaceBetween size={'s'}>
              <div
                style={{
                  height: '50vh',
                  position: 'relative',
                  overflowY: 'hidden'
                }}
                data-tour-id="formatingBar"
                // data-tour-id="buildAnswer"
              >
                <ReactQuill
                  ref={reactQuillRef}
                  style={{
                    height: '100%',
                    overflowY: 'scroll',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%'
                  }}
                  theme="snow"
                  placeholder="Your selected answers go here. You can edit and format your response."
                  value={localAnswerString}
                  onChange={setLocalAnswerString}
                />
              </div>
              {/* <Textarea
                value={notesValue}
                onChange={({ detail }) => setNotesValue(detail.value)}
                placeholder="You can type notes here! Not part of download."
              /> */}
              <Notes
                
                notes={selectedItems[0]?.notes}
                setNotesValue={setNotesValue}
                notesValue={notesValue}
              />
              <SpaceBetween
                size={'s'}
                direction="horizontal"
                data-tour-id="qandaSearchScope"
              >
                <PopoverWrapper
                  sideToShow="RIGHT"
                  aboveBelow="TOP"
                  popoverContent={
                    'Research the Knowledgebase to find other relevant info'
                  }
                >
                  <Button
                    disabled={currentDoc.status === 'Published'}
                    onClick={() => {
                      handleResearch();
                      setQAOpen(false);
                    }}
                    data-tour-id="researchButton"
                  >
                    Research
                  </Button>
                </PopoverWrapper>
                {currentDoc?.status !== 'Published' && (
                  <Multiselect
                    selectedOptions={selectedTypes}
                    onChange={({ detail }) =>
                      setSelectedTypes([...detail.selectedOptions])
                    }
                    placeholder={
                      selectedTypes?.length > 0
                        ? 'Use selected types'
                        : 'Select Types to research'
                    }
                    hideTokens
                    options={KBOptions}
                  ></Multiselect>
                )}
              </SpaceBetween>
            </SpaceBetween>
          </Container>
        </div>
      </Grid>
    </Modal>
  );
};
