import {
  Button,
  ExpandableSection,
  SpaceBetween,
  Tabs
} from '@cloudscape-design/components';
import { KnowledgeBaseWrapper } from '../knowledgeBaseWrapper';
import { DocumentMapper } from './DocumentMapper';
import { DocumentContext } from '../../../context/documentContext';
import { useContext } from 'react';
import { KnowledgeBaseWrapperNonExp } from '../knowledgeBaseWrapperNonExpand';
import { ParentDocumentMapper } from './ParentDocumentMapper';
import { NetNewComponent } from './netNetComponent';
import { HelpContext } from '../../../context/helpContext';
import { tourNameEmun } from '../../../models/interfaces';

export const ConnectDocument = () => {
  const {
    setSelectedItems,
    connectComponentOpen,
    setConnectComponentOpen,
    connectActiveTabId,
    setConnectActiveTabId
  } = useContext(DocumentContext);
  const { setHelpModalVisible, setTourType } = useContext(HelpContext);
  return (
    <ExpandableSection
      headerText="Connect Information"
      headerActions={
        <SpaceBetween size={'xxxs'}>
          <Button
            onClick={() => {
              setHelpModalVisible(true);
              setTourType(tourNameEmun.CONNECT);
            }}
            // variant="icon"
            // iconName="status-info"
          >
            Show Tour
          </Button>
        </SpaceBetween>
      }
      variant="container"
      headerDescription="Search Knowledge Base or Project Documents"
      data-tour-id="docConnectInformation"
      expanded={connectComponentOpen}
      onChange={() => setConnectComponentOpen(!connectComponentOpen)}
    >
      <Tabs
        activeTabId={connectActiveTabId}
        onChange={({ detail }) => setConnectActiveTabId(detail.activeTabId)}
        tabs={[
          {
            label: 'Project Mapping',
            id: 'tab1',
            content: <DocumentMapper />
          },
          {
            label: 'KnowledgeBase Mapping',
            id: 'tab2',
            content: <KnowledgeBaseWrapperNonExp />
          },
          {
            label: 'Referenced By',

            id: 'tab3',
            content: <ParentDocumentMapper data-tour-id="docReferencedBy" />
          },
          {
            label: 'Supplemental',

            id: 'tab4',
            content: <NetNewComponent />
          }
        ]}
        // onChange={() => setSelectedItems([])}
      />
    </ExpandableSection>
  );
};
