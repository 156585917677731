import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//models
import {
  DocumentContextModel,
  sentenceItem,
  IControl,
  IDomain,
  documentTableItem,
  phraseItem,
  fileUploadItem,
  kbSentenceItem,
  QandASentence,
  DOCTYPE,
  filterEnum,
  ControlsNavigation,
  AlertInfo,
  netNewItem,
  NetNewSentenceItem
} from '../models/interfaces';
// dummy data for dev
import axios from 'axios';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { sortAndRemoveHidden } from '../helpers/sortAndDisplay';
import { TableProps } from '@cloudscape-design/components';
import { UserContext } from './authContext';
import { getKMFilters } from '../helpers/getKnowledgeMapFilters';
import { buildNavObject } from '../helpers/buildNavObjectForButtons';
import { ProjectContext } from './projectContext';
interface Props {
  children?: ReactNode;
}

export const DocumentContext = createContext({} as DocumentContextModel);

export const DocumentContextProvider: React.FC<Props> = (props) => {
  const { auth, token, user, isExpired } = useContext(UserContext);
  const { setMainProjects, setAllFolders } = useContext(ProjectContext);
  const [sentences, setSentences] = useState<sentenceItem[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [controls, setControls] = useState<IControl[]>([]);
  const [selectedDomainOption, setSelectedDomainOption] =
    useState<OptionDefinition>({
      label: 'Option 1',
      value: '1'
    });
  const [selectedControlOptions, setSelectedControlOptions] =
    useState<OptionDefinition>({ label: 'No Selection', value: 'NS' });
  const [currentDoc, setCurrentDoc] = useState<documentTableItem>(
    {} as documentTableItem
  );
  const [currentDocId, setCurrentDocId] = useState<string | null>();
  const [selectType, setSelectType] =
    useState<TableProps.SelectionType>('single');
  const [selectedItems, setSelectedItems] = useState<
    sentenceItem[] | phraseItem[] | QandASentence[]
  >([]);
  const [notesValue, setNotesValue] = useState<string>('');
  const [filesToUpload, setFilesToUpload] = useState<fileUploadItem[]>([]);
  const [uploadProcessing, setUploadProcessing] = useState<boolean>(false);
  const [currentFolder, setCurrentFolder] = useState<string>('');
  const [knowledgeBaseModal, setKnowledgeBaseModal] = useState<boolean>(false);
  const [knowledgeBaseSentences, setKnowledgeBaseSentences] = useState<
    kbSentenceItem[]
  >([]);
  const [selectedItemsKbResults, setSelectedItemsKbResults] = useState<
    kbSentenceItem[]
  >([]);
  const [kbLoading, setKbLoading] = useState<boolean>(false);
  const [refreshKbLoading, setRefreshKbLoading] = useState<boolean>(false);
  const [updateSentenceLoading, setUpdateSentenceLoading] =
    useState<boolean>(false);
  const [savePinnedLoading, setSavePinnedLoading] = useState<boolean>(false);
  const [freeformSelectedItemsKB, setFreeformeSelectedItemsKB] = useState<
    kbSentenceItem[]
  >([]);
  const [freeformSelectedItemsQA, setFreeformeSelectedItemsQA] = useState<
    QandASentence[]
  >([]);
  const [kbSelectedItems, setKBSelectedItems] = useState<kbSentenceItem[]>([]);
  const [qaSelectedItems, setQASelectedItems] = useState<QandASentence[]>([]);
  const [kbFilterOptions, setKBFilterOptions] = useState<OptionDefinition[]>(
    []
  );
  const [qaOrigin, setQAOrigin] = useState<string>('');
  const [qaContact, setQAContact] = useState<{}>({});
  const [selectedItemsQAResults, setSelectedItemsQAResults] = useState<
    QandASentence[]
  >([]);
  const [showQandQModal, setShowQandAModal] = useState<boolean>(false);
  const [kbModalFreeText, setKBModalFreeText] = useState<string>('');
  const [docViewFilter, setDocViewFilter] = useState<filterEnum | null>(null);
  const [controlFilter, setControlFilter] = useState<string>('');
  const [burdenAttFilter, setBurdenAttFilter] = useState<string[]>([]);
  const [controlsNavigation, setControlsNavigation] =
    useState<ControlsNavigation>({});
  const [filterButtonNav, setFilterButtonNav] = useState<ControlsNavigation>(
    {}
  );
  const [mainAlert, setMainAlert] = useState<AlertInfo>({
    showAlert: false,
    message: '',
    alertType: 'success'
  });
  const [allDocuments, setAllDocuments] = useState<documentTableItem[]>([]);
  const [mappedSaveLoading, setMappedSaveLoading] = useState<boolean>(false);
  const [aggSentencesLoading, setAggSentencesLoading] =
    useState<boolean>(false);
  const [currentAggregatedDocumentIds, setCurrentAggregatedDocumentIds] =
    useState<string[]>([]);
  const [primaryDocument, setPrimaryDocument] = useState<netNewItem | null>(
    null
  );
  const [netNewSentencesForDoc, setNetNewSentencesForDoc] = useState<
    NetNewSentenceItem[]
  >([]);
  const [reportNameFromButton, setReportNameFromButton] = useState<string>('');
  const [connectComponentOpen, setConnectComponentOpen] =
    useState<boolean>(false);
  const [connectActiveTabId, setConnectActiveTabId] = useState<string>('tab1');
  const [CategorizeExpanded, setCategoriseExpanded] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (currentDocId) {
      getDocument(currentDocId);
      setDocViewFilter(null);
      getAllNetNew(currentDocId);
      // getSentences(currentDocId);
      // getKnowledgeBase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocId]);

  // this need to only be here move from documentTba;eVIew
  const getAllDocs = async () => {
    if (!token || isExpired(token)) return;
    // const docs = await axios.post(
    //   `${process.env.REACT_APP_KORU_API}/document?type=all`,
    //   {},
    //   auth
    // );
    // if (docs.data.result) {
    //   setAllDocuments(docs.data.result);
    // }

    // new by access control
    try {
      const docs = await axios.post(
        `${process.env.REACT_APP_KORU_API}/document?type=allByUser`,
        {},
        auth
      );
      if (docs.data.result) {
        console.log('result for docs', docs.data.result);
        setAllDocuments(docs.data.result.documents);
        setMainProjects(docs.data.result.projects);
        setAllFolders(docs.data.result.folders);
      }
    } catch (e) {
      const error = e as Error;
      console.log('ERROR', error.message);
    }
  };

  const getAllDocsWithResult = async () => {
    if (!token || isExpired(token)) return;
    const docs = await axios.post(
      `${process.env.REACT_APP_KORU_API}/document?type=all`,
      {},
      auth
    );
    if (docs.data.result) {
      return docs.data.result;
    } else {
      return null;
    }
  };

  const getDocContinous = (allDocumentsLocal: documentTableItem[]) => {
    const docList = allDocumentsLocal.map((ele) => ele.status);
    const route = location.pathname.split('/');
    console.log('route ======', route);
    if (!docList.includes('Analyzing') || route[2] !== 'projects') {
      return;
    } else {
      getAllDocs();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getDocContinous(allDocuments);
    }, 15000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDocuments]);
  useEffect(() => {
    getAllDocs();
  }, []);
  useEffect(() => {
    getAllDocs();
  }, [auth]);

  useEffect(() => {
    if (currentDoc.documentType === 'questionnaire') {
      getCurrentSentenceQA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const getDomains = async () => {
    try {
      const domResult = await axios.post(
        `${process.env.REACT_APP_KORU_API}/domain-control?type=domains`,
        { domainType: [process.env.REACT_APP_DELPOYMENT_TYPE] },
        auth
      );

      setDomains(domResult.data.result);
    } catch (e) {
      console.log('error from get domains', e);
    }
  };

  const getControls = async () => {
    try {
      const conResult = await axios.post(
        `${process.env.REACT_APP_KORU_API}/domain-control?type=controls`,
        { controlType: [process.env.REACT_APP_DELPOYMENT_TYPE] },
        auth
      );
      setControls(conResult.data.result);
    } catch (e) {
      console.log('error from get controls', e);
    }
  };
  const getDocument = async (documentId: string) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/document?type=documentById`,
        { documentId: currentDocId },
        auth
      );
      setCurrentDoc(result.data.result[0]);
      if (result.data.result[0].documentType === DOCTYPE.questionnaire) {
        getSentencesQA(documentId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSentences = async (documentId: string, chunkNo?: number) => {
    try {
      const senResult = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentence?type=all`,
        { documentId, chunkNo },
        auth
      );
      setSentences([...senResult.data.result]);
    } catch (e) {
      console.log(e);
    }
  };

  const getSentencesQA = async (documentId: string, chunkNo?: number) => {
    try {
      const senResult = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentence?type=qaAll`,
        { documentId },
        auth
      );
      setSentences([...senResult.data.result]);
    } catch (e) {
      console.log(e);
    }
  };

  const createPhrase = async (sentencesForPhrase: sentenceItem[]) => {
    const phraseBody = {
      sentencesForPhrase
    };
    try {
      const phraseResult = await axios.post(
        `${process.env.REACT_APP_API}/phrase`,
        phraseBody,
        auth
      );

      if (phraseResult.status === 200) {
        const tempSentences = sentences;
        for (const sentence of sentencesForPhrase) {
          const index = tempSentences.findIndex(
            (ele) => sentence._id === ele._id
          );
          tempSentences[index].hidden = true;
        }
        tempSentences.push(phraseResult.data);
        const sorted = sortAndRemoveHidden(tempSentences);
        setSentences([...sorted]);
        setSelectedItems([phraseResult.data]);
        setSelectType('single');
      }
    } catch (e) {
      console.log('error from phrase', e);
    }
  };
  useEffect(() => {
    setNotesValue('');
  }, [selectedItems]);

  const updateSentence = async () => {
    if (selectedItems.length === 1) {
      setUpdateSentenceLoading(true);
      const itemToUpdate = selectedItems[0];

      const updateBody = {
        updates: {
          notes: notesValue,
          predicted_label_domain: selectedDomainOption.label,
          predicted_label_control: selectedControlOptions.label,
          changedControls:
            selectedControlOptions.label !==
            itemToUpdate.predicted_label_control,
          changedDomains:
            selectedDomainOption.label !== itemToUpdate.predicted_label_control
        },
        sentenceId: itemToUpdate._id
      };
      let updatedItem: sentenceItem[];
      const tempSentences = sentences;
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_KORU_API}/sentence?type=update`,
          updateBody,
          auth
        );
        updatedItem = result.data.result;
        const indexOfUpdateItem = tempSentences.findIndex(
          (ele: sentenceItem) => updatedItem[0]._id === ele._id
        );
        if (indexOfUpdateItem !== -1) {
          tempSentences[indexOfUpdateItem] = updatedItem[0];
          setSentences([...tempSentences]);
          setSelectedItems([updatedItem[0]]);
        }
        setUpdateSentenceLoading(false);
        // update controls navigation
        const tempNavObject = buildNavObject(sentences);
        setControlsNavigation(tempNavObject.controls);
        setFilterButtonNav(tempNavObject.buttons);
      } catch (e) {
        console.log('error from update', e);
      }
    }
  };

  const updateQASentence = async (text: string, htmlString: string) => {
    if (selectedItems.length === 1) {
      setUpdateSentenceLoading(true);
      const kbSentenceIds = kbSelectedItems.map((ele) => ele._id);
      const qaSelectedIds = qaSelectedItems.map((ele) => ele._id);
      const itemToUpdate = selectedItems[0];
      const updateBody = {
        updates: {
          notes: notesValue,
          kbSentences: kbSelectedItems,
          qaSentences: qaSelectedIds,
          answer: text,
          htmlAnswer: htmlString
        },
        sentenceId: itemToUpdate.sentenceId
      };
      const tempSentences = sentences;
      let updatedItem: QandASentence;
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_KORU_API}/sentence?type=updateQASentence`,
          updateBody,
          auth
        );
        updatedItem = result.data.result;
        const indexOfUpdateItem = tempSentences.findIndex(
          (ele: QandASentence | sentenceItem) => updatedItem._id === ele._id
        );
        if (indexOfUpdateItem !== -1) {
          tempSentences[indexOfUpdateItem] = updatedItem;
          setSentences([...tempSentences]);
          setSelectedItems([]);
        }
        setUpdateSentenceLoading(false);
      } catch (e) {
        console.log('error updating QA sentence', e);
      }
    }
  };

  const breakPhraseApart = async () => {
    const tempItem = selectedItems[0];
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API}/breakphraseapart`,
        selectedItems[0],
        auth
      );
      console.log('results break apart', result);
      if (result.status === 200) {
        const newSentenceArray = sentences.filter(
          (ele) => ele._id !== tempItem._id
        );
        newSentenceArray.push(...result.data);
        const sortedArray = sortAndRemoveHidden(newSentenceArray);
        setSentences(sortedArray);
        setSelectedItems([result.data[0]]);
      }
    } catch (e) {
      console.log('error in breakapart', e);
    }
  };

  const addKBsentences = async (
    sentenceIds: kbSentenceItem[],
    close: boolean
  ) => {
    if (selectedItems.length === 1) {
      setSavePinnedLoading(true);
      const itemToUpdate = selectedItems[0];
      const updateBody = {
        updates: {
          kbSentences: sentenceIds
        },
        sentenceId: itemToUpdate._id
      };
      let updatedItem: sentenceItem[];

      try {
        const result = await axios.post(
          `${process.env.REACT_APP_KORU_API}/sentence?type=update`,
          updateBody,
          auth
        );
        updatedItem = result.data.result;
        const tempSentences = sentences;
        const indexOfUpdateItem = tempSentences.findIndex(
          (ele: sentenceItem) => updatedItem[0]._id === ele._id
        );
        if (indexOfUpdateItem !== -1) {
          tempSentences[indexOfUpdateItem] = updatedItem[0];
          setSentences([...tempSentences]);
          setSelectedItems([updatedItem[0]]);
        }
        setSavePinnedLoading(false);
        if (close) {
          setShowModal(false);
        }
      } catch (e) {
        console.log('error from update', e);
      }
    }
  };

  const saveMappedItems = async (
    itemsToAdd: kbSentenceItem[],
    type: string
  ) => {
    try {
      setMappedSaveLoading(true);
      const updateBody = {
        updates: {
          mappedTargetSentences: itemsToAdd
        },
        sentenceId: selectedItems[0]._id
      };
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentence?type=update`,
        updateBody,
        auth
      );
      const updatedItem = result.data.result;
      // update existing sentences
      const temp = sentences;
      const index = temp.findIndex(
        (ele) => ele.sentenceId === selectedItems[0].sentenceId
      );
      temp[index] = updatedItem[0];
      setSentences([...temp]);
      setSelectedItems([updatedItem[0]]);

      setMappedSaveLoading(false);
    } catch (e) {
      setMappedSaveLoading(false);
      const error = e as Error;
      console.log('error from update', error.message);
    }
  };

  const updateStarred = async () => {
    if (selectedItems.length === 1) {
      let updatedItem: sentenceItem[];
      const itemToUpdate = selectedItems[0];
      const updateBody = {
        updates: {
          starred: !itemToUpdate.starred
        },
        sentenceId: itemToUpdate._id
      };
      try {
        const tempSentences = sentences;
        const result = await axios.post(
          `${process.env.REACT_APP_KORU_API}/sentence?type=update`,
          updateBody,
          auth
        );
        updatedItem = result.data.result;

        const indexOfUpdateItem = tempSentences.findIndex(
          (ele: sentenceItem | phraseItem) => updatedItem[0]._id === ele._id
        );
        console.log('Updated index', indexOfUpdateItem);
        if (indexOfUpdateItem !== -1) {
          tempSentences[indexOfUpdateItem] = updatedItem[0];
          setSentences([...tempSentences]);
          setSelectedItems([updatedItem[0]]);
        }
      } catch (e) {
        console.log('error', e);
      }
    }
  };
  const updateFocus = async () => {
    if (selectedItems.length === 1) {
      const itemToUpdate = selectedItems[0];
      const updateBody = {
        updates: {
          burden: !itemToUpdate.burden
        },
        sentenceId: itemToUpdate._id
      };
      let updatedItem: sentenceItem[];
      const tempSentences = sentences;
      try {
        if ('sentences' in itemToUpdate) {
          const result = await axios.put(
            `${process.env.REACT_APP_API}/updatePhrase`,
            updateBody,
            auth
          );
          updatedItem = result.data;
        } else {
          const result = await axios.post(
            `${process.env.REACT_APP_KORU_API}/sentence?type=update`,
            updateBody,
            auth
          );
          updatedItem = result.data.result;
        }
        const indexOfUpdateItem = tempSentences.findIndex(
          (ele: sentenceItem) => updatedItem[0]._id === ele._id
        );
        if (indexOfUpdateItem !== -1) {
          tempSentences[indexOfUpdateItem] = updatedItem[0];
          setSentences([...tempSentences]);
          setSelectedItems([updatedItem[0]]);
        }
      } catch (e) {
        console.log('error from update', e);
      }
    }
  };

  const updateRedline = async () => {
    if (selectedItems.length === 1) {
      const itemToUpdate = selectedItems[0];
      const newRedline = !itemToUpdate.redlined;
      const updateBody = {
        updates: {
          redlined: newRedline,
          notes: newRedline ? 'Redlined' : 'Removed Redline'
        },
        sentenceId: itemToUpdate._id
      };
      let updatedItem: sentenceItem[];
      const tempSentences = sentences;
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_KORU_API}/sentence?type=update`,
          updateBody,
          auth
        );
        updatedItem = result.data.result;

        const indexOfUpdateItem = tempSentences.findIndex(
          (ele: sentenceItem) => updatedItem[0]._id === ele._id
        );
        if (indexOfUpdateItem !== -1) {
          tempSentences[indexOfUpdateItem] = updatedItem[0];
          setSentences([...tempSentences]);
          setSelectedItems([updatedItem[0]]);
        }
      } catch (e) {
        console.log('error from update', e);
      }
    }
  };

  const updateNetNew = async (netNew: netNewItem) => {
    if (selectedItems.length === 1) {
      const itemToUpdate = selectedItems[0];

      const updateBody = {
        netNew,
        remove:
          itemToUpdate.netNew && itemToUpdate.netNew.length > 0 ? true : false,
        sentenceId: itemToUpdate._id
      };
      let updatedItem: sentenceItem[];
      const tempSentences = sentences;
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_KORU_API}/sentence?type=updateNetNew`,
          updateBody,
          auth
        );
        updatedItem = result.data.result;
        console.log('updated item', updatedItem);
        const indexOfUpdateItem = tempSentences.findIndex(
          (ele: sentenceItem) => updatedItem[0]._id === ele._id
        );
        console.log('index before if', indexOfUpdateItem);
        if (indexOfUpdateItem !== -1) {
          console.log('updating index ', indexOfUpdateItem);
          tempSentences[indexOfUpdateItem] = updatedItem[0];
          setSentences([...tempSentences]);
          setSelectedItems([updatedItem[0]]);
        }
      } catch (e) {
        console.log('error from update', e);
      }
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    getControls();
    getDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getCurrentSentenceKB = async (
    sourceSentence: string,
    kbTypes?: string[]
  ) => {
    const tempSentence = selectedItems[0];
    // const originalKBItems = kbSelectedItems.map((ele) => ele._id);
    const originalKBItems = selectedItems[0].kbSentences.map((ele) => ele._id);
    console.log('original kb items ids', originalKBItems);
    if (tempSentence) {
      const requestBody: {
        sentence: any;
        knowledge_base_type?: any;
        type: string;
        sentencePercent: number;
        paragraphPercent: number;
      } = {
        sentence: tempSentence,
        type: sourceSentence,
        sentencePercent: 55 / 100,
        paragraphPercent: 40 / 100
      };
      if (kbTypes) {
        requestBody[`knowledge_base_type`] = kbTypes;
      }
      setKbLoading(true);
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_KORU_API}/sentenceVector?type=kbSearchFromSentence`,
          requestBody,
          auth
        );
        const resultExlDups = result.data.results.filter(
          (ele: kbSentenceItem) => !originalKBItems.includes(ele._id)
        );
        console.log('result ex dups', resultExlDups);
        console.log('current temp results', selectedItemsKbResults);
        const combined = [...resultExlDups, ...selectedItems[0].kbSentences];
        console.log('combined', combined);
        // setSelectedItemsKbResults([
        //   ...resultExlDups,
        //   ...selectedItems[0].kbSentences
        // ]);

        setSelectedItemsKbResults(combined);
        setKbLoading(false);
      } catch (e) {
        console.log('error from getKBresults', e);
        setSelectedItemsKbResults([]);
        setKbLoading(false);
      }
    }
  };

  const getCurrentSentenceQA = async () => {
    const tempSentence = selectedItems[0];

    if (tempSentence) {
      setKbLoading(true);
      try {
        // change to vector search
        const result = await axios.post(
          `${process.env.REACT_APP_KORU_API}/sentenceVector?type=vectorFromQASentence`,
          {
            sentence: tempSentence
          },
          auth
        );
        setSelectedItemsQAResults(result.data.results);
        setKbLoading(false);
      } catch (e) {
        console.log('error from getQAresults', e);
        setSelectedItemsQAResults([]);
        setKbLoading(false);
      }
    }
    // else {
    //   if (tempSentence?.qaSentences && tempSentence.qaSentences.length > 0) {
    //     setSelectedItemsQAResults(tempSentence.qaSentences!);
    //   }
    // }
  };

  const qandaPassthroughAnalysis = async (text: string) => {
    try {
      const embeddingBody = [{ text: text, _id: 'testid' }];
      const embeddingResult = await axios.post(
        `${process.env.REACT_APP_KORU_API}/embedding`,
        embeddingBody,
        auth
      );
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentenceVector?type=vectorQandA`,
        { vector: embeddingResult.data[0].embedding },
        auth
      );

      return result.data;
    } catch (e) {
      console.log('error from getQAresults', e);
      const errror = e as Error;
      throw Error(errror.message);
    }
  };

  useEffect(() => {
    const filtersValue = getKMFilters(selectedItemsKbResults);
    const tempArrayOfFilters: OptionDefinition[] = [];
    for (const value of filtersValue) {
      tempArrayOfFilters.push({ label: value, value: value });
    }
    setKBFilterOptions(tempArrayOfFilters);
  }, [selectedItemsKbResults]);

  const freeHandleAnalyze = async (
    sentenceText: string,
    kbFilters?: OptionDefinition[]
  ) => {
    try {
      const embeddingBody = [{ text: sentenceText, _id: 'testid' }];
      const embeddingResult = await axios.post(
        `${process.env.REACT_APP_KORU_API}/embedding`,
        embeddingBody,
        auth
      );
      let requestBody: { vector: number[]; knowledge_base_type?: string[] } = {
        vector: embeddingResult.data[0].embedding
      };
      if (kbFilters && kbFilters.length > 0) {
        requestBody.knowledge_base_type = kbFilters.map((ele) => ele.label!);
      }
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentenceVector?type=vectorOnly`,
        requestBody,
        auth
      );

      return result.data.results;
      // return;
    } catch (e) {
      console.log('error from passthrough', e);
      const error = e as Error;
      throw Error(error.message);
    }
  };

  const refreshCurrentSentenceKB = async () => {
    const tempSentence = selectedItems[0];
    if (tempSentence) {
      setRefreshKbLoading(true);
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API}/kbSearchNew`,
          {
            sentence: tempSentence
          },
          auth
        );
        setRefreshKbLoading(false);
        if (currentDocId) {
          getSentences(currentDocId);
        }
        return result.data;
      } catch (e) {
        console.log('error from getKBresults', e);
        setRefreshKbLoading(false);
        return [];
      }
    }
  };

  const deleteDocument = async (doc: documentTableItem) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/document?type=deleteByID`,
        { documentId: doc._id },
        auth
      );
      return result.data.result;
    } catch (e) {
      console.log(`Error in deleting ${doc.documentFileName}`);
      return e;
    }
  };
  const uploadFiles = async (type: string | null) => {
    if (filesToUpload.length === 0) return;
    setUploadProcessing(true);
    const projectId = filesToUpload[0].project;
    for (let i = 0; i < filesToUpload.length; i++) {
      try {
        const docCreateResult = await axios.post(
          `${process.env.REACT_APP_KORU_API}/document?type=addNew`,
          {
            folder: currentFolder,
            documentFileName: filesToUpload[i].name,
            documentType: type,
            qaOrigin: qaOrigin,
            qaContact: qaContact
          },
          auth
        );
        const tempDocUpdate = filesToUpload;
        tempDocUpdate[i].uploaded = 'Document Created';
        setFilesToUpload([...tempDocUpdate]);
        try {
          const uplaodResult = await axios.post(
            `${process.env.REACT_APP_KORU_API}/sentence?type=addQA`,
            {
              documentId: docCreateResult.data.result._id,
              sentences: filesToUpload[i].sentences,
              documentType: type
            },
            auth
          );
          const tempFiles = filesToUpload;
          tempFiles[i].uploaded = 'Aanlyzing Document';
          setFilesToUpload([...tempFiles]);
        } catch (e) {
          const tempFiles = filesToUpload;
          tempFiles[i].uploaded = 'Error in sentence creation';
          setFilesToUpload([...tempFiles]);
        }
      } catch (e) {
        const tempFiles = filesToUpload;
        tempFiles[i].uploaded = 'Error in doc creation';
        setFilesToUpload([...tempFiles]);
      }
    }
    setFilesToUpload([]);
    setUploadProcessing(false);
    await getAllDocs();
    navigate(`/app/projects/${projectId}/folder/${currentFolder}`);
  };
  const addDocToKnowledgeBase = async (docId: string, docType: string) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/document?type=addDocToKB`,
        { documentId: docId, knowledge_base_type: docType },
        auth
      );
      return result;
    } catch (e) {
      return 'error';
    }
  };

  const kBulkUpload = async (info: FormData) => {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/jsonUpload`,
      info,
      auth
    );
    return result;
  };

  const removeAllKBReferences = async () => {
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API}/removeAllKBReferences`,
        {},
        auth
      );
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleCloseQnadAModal = () => {
    setShowQandAModal(false);
    setSelectedItemsKbResults([]);
    setSelectedItemsQAResults([]);
    setSelectedItemsKbResults([]);
  };

  const handleSearch = async () => {
    try {
      setKbLoading(true);
      const originalKBItems = selectedItems[0]?.kbSentences.map(
        (ele) => ele._id
      );
      const result = await freeHandleAnalyze(kbModalFreeText);
      const resultExlDups = result.filter(
        (ele: kbSentenceItem) => !originalKBItems.includes(ele._id)
      );
      const tempUpdateArray = [...resultExlDups, ...kbSelectedItems];
      setSelectedItemsKbResults(resultExlDups);
      setKbLoading(false);
    } catch (e) {
      console.log('error from freehand search');
    }
  };

  const publishQandADoc = async () => {
    if (!currentDocId) return;
    try {
      await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentence?type=publishQA`,
        { documentId: currentDocId },
        auth
      );
      setMainAlert({
        showAlert: true,
        message: 'Document Published',
        alertType: 'success'
      });
    } catch (e) {
      console.log('error publishing doc', e);
      setMainAlert({
        showAlert: true,
        message: 'Error Publishing Document',
        alertType: 'error'
      });
    }
  };

  const resetDocumentState = () => {
    setSentences([]);
    setSelectedItems([]);
    setKBSelectedItems([]);
    setCurrentDocId(null);
  };

  const getAggregatedViewSentences = async (documentIds: string[]) => {
    try {
      setCurrentAggregatedDocumentIds(documentIds);
      setAggSentencesLoading(true);
      let docsToGet;
      if (allDocuments.length === 0) {
        const tempAllDocs: documentTableItem[] = await getAllDocsWithResult();
        docsToGet = tempAllDocs.filter((ele) => documentIds.includes(ele._id));
      } else {
        docsToGet = allDocuments.filter((ele) => documentIds.includes(ele._id));
      }
      const localSentences = [];
      for (const doc of docsToGet) {
        console.log('getting do Id', doc._id);
        let currentPage = 1;
        let totalPages = 1;
        while (currentPage <= totalPages) {
          console.log('top of while', currentPage);
          console.log('top of while', totalPages);
          let reqObject;
          if (currentPage === 1) {
            reqObject = {
              documentId: doc._id,
              currentPage
            };
          } else {
            reqObject = {
              documentId: doc._id,
              currentPage,
              totalPages
            };
          }
          console.log('request object::', reqObject);
          const senResult = await axios.post(
            `${process.env.REACT_APP_KORU_API}/sentence?type=multidocSizes`,
            reqObject,
            auth
          );
          console.log('get result ==', senResult);
          const newSentences = senResult.data.result
            .result as unknown as sentenceItem[];
          localSentences.push(...newSentences);
          currentPage = senResult.data.result.currentPage;
          totalPages = senResult.data.result.totalPages;
          console.log('bottom of while', currentPage);
          console.log('bottom of while', totalPages);
        }

        // for (let i = 0; i < doc.NoOfChunks; i++) {
        //   console.log('Sentence Size results', senResult);
        // }
      }

      // items.sort((a, b) => {
      //   if (a.pageNumber === b.pageNumber) {
      //     return a.indexNumber - b.indexNumber;
      //   }
      //   return a.pageNumber - b.pageNumber;
      // });
      const sorted = localSentences.sort((a, b) => {
        if (a.PageNumber === b.PageNumber) {
          return a.sentenceIndex - b.sentenceIndex;
        }
        return a.PageNumber - b.PageNumber;
      });
      setSentences(sorted);
      setAggSentencesLoading(false);
    } catch (e) {
      const error = e as Error;
      console.log('get all multidocument sentences', error.message);
      setAggSentencesLoading(false);
    }
  };

  const getAllNetNew = async (docId: string) => {
    try {
      const senResult = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentence?type=getNetNewSentences`,
        { _id: docId },
        auth
      );
      const netNewSentences = senResult.data.result
        .result as unknown as sentenceItem[];
      const aggResults = senResult.data.result
        .AggregateResult as unknown as NetNewSentenceItem[];
      console.log('aggregated net new', aggResults);
      setNetNewSentencesForDoc(aggResults);
    } catch (e) {
      const error = e as Error;
      console.log('New new Error');
    }
  };

  const value = {
    sentences,
    setSentences,
    showModal,
    setShowModal,
    controls,
    domains,
    auth,
    getSentences,
    setSelectedControlOptions,
    setSelectedDomainOption,
    selectedControlOptions,
    selectedDomainOption,
    createPhrase,
    currentDoc,
    currentDocId,
    setCurrentDocId,
    setCurrentDoc,
    selectType,
    setSelectType,
    selectedItems,
    setSelectedItems,
    updateSentence,
    notesValue,
    setNotesValue,
    breakPhraseApart,
    updateFocus,
    filesToUpload,
    setFilesToUpload,
    uploadProcessing,
    setUploadProcessing,
    currentFolder,
    setCurrentFolder,
    uploadFiles,
    deleteDocument,
    knowledgeBaseModal,
    setKnowledgeBaseModal,
    updateRedline,
    knowledgeBaseSentences,
    selectedItemsKbResults,
    addKBsentences,
    addDocToKnowledgeBase,
    kBulkUpload,
    getCurrentSentenceKB,
    setKbLoading,
    kbLoading,
    setSelectedItemsKbResults,
    refreshCurrentSentenceKB,
    refreshKbLoading,
    removeAllKBReferences,
    updateSentenceLoading,
    getControls,
    getDomains,
    freeHandleAnalyze,
    freeformSelectedItemsKB,
    setFreeformeSelectedItemsKB,
    freeformSelectedItemsQA,
    setFreeformeSelectedItemsQA,
    kbSelectedItems,
    setKBSelectedItems,
    kbFilterOptions,
    qaOrigin,
    setQAOrigin,
    qaContact,
    setQAContact,
    selectedItemsQAResults,
    setSelectedItemsQAResults,
    showQandQModal,
    setShowQandAModal,
    getCurrentSentenceQA,
    handleCloseQnadAModal,
    qaSelectedItems,
    setQASelectedItems,
    updateQASentence,
    kbModalFreeText,
    setKBModalFreeText,
    handleSearch,
    publishQandADoc,
    resetDocumentState,
    qandaPassthroughAnalysis,
    docViewFilter,
    setDocViewFilter,
    controlFilter,
    setControlFilter,
    savePinnedLoading,
    setSavePinnedLoading,
    controlsNavigation,
    setControlsNavigation,
    burdenAttFilter,
    setBurdenAttFilter,
    mainAlert,
    setMainAlert,
    updateStarred,
    allDocuments,
    setAllDocuments,
    saveMappedItems,
    mappedSaveLoading,
    setMappedSaveLoading,
    getAggregatedViewSentences,
    aggSentencesLoading,
    setAggSentencesLoading,
    getAllDocs,
    filterButtonNav,
    setFilterButtonNav,
    updateNetNew,
    primaryDocument,
    setPrimaryDocument,
    netNewSentencesForDoc,
    reportNameFromButton,
    setReportNameFromButton,
    connectComponentOpen,
    setConnectComponentOpen,
    CategorizeExpanded,
    setCategoriseExpanded,
    connectActiveTabId,
    setConnectActiveTabId
  };
  return (
    <DocumentContext.Provider value={value}>
      {props.children}
    </DocumentContext.Provider>
  );
};
