interface Props {
  index: string;
  title: string;
  description: string;
}

export const StartPageLine: React.FC<Props> = ({
  index,
  title,
  description
}) => {
  return (
    <div
      data-id="outside"
      style={{ display: 'flex', gap: '1rem', alignItems: 'baseline' }}
    >
      <div style={{ flexShrink: 0 }}>
        <span
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '1.5rem',
            height: '1.5rem',
            borderRadius: '9999px',
            backgroundColor: '#E0F7FA', // bg-blue-100
            color: '#1E40AF', // text-blue-600
            fontWeight: '500'
          }}
        >
          {index}
        </span>
      </div>
      <div>
        <h3 style={{ fontWeight: '500', color: '#1F2937' }}>{title}</h3>
        {description !== '' ? (
          <p style={{ fontSize: '0.875rem', color: '#4B5563' }}>
            {description}
          </p>
        ) : (
          <p> </p>
        )}
      </div>
    </div>
  );
};
