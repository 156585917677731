import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Container,
  Table,
  SpaceBetween,
  Button,
  Header,
  Modal,
  FormField,
  Input,
  Pagination,
  Select,
  Multiselect
} from '@cloudscape-design/components';
import { FolderItem, Projects, tourNameEmun } from '../models/interfaces';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { ProjectContext } from '../context/projectContext';
import { DocumentContext } from '../context/documentContext';
import { UserContext } from '../context/authContext';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import axios from 'axios';
import { manageShare } from '../helpers/manageShareHelpers';
import { HelpContext } from '../context/helpContext';
import { BackAndHomeButtons } from './Components/help/backAndHomeButtons';
import Cookies from 'js-cookie';

export const FolderTableView = () => {
  const {
    selectedProjects,
    setSelectedProjects,
    createNewFolder,
    setFolderName,
    folderName,
    createFolderLoading,
    updateFolder,
    newFolderModalVisible,
    setNewFolderModalVisible,
    deleteFolder,
    selectedFolders,
    setSelectedFolder,
    projectIdContext,
    setProjectIdContext,
    folderDescription,
    setFolderDescription,
    currentProjectFolders,
    deleteLoadingFolder
  } = useContext(ProjectContext);
  const { allDocuments, setAllDocuments, setCurrentFolder, getAllDocs } =
    useContext(DocumentContext);
  const { viewed, setTourType, setShowTourStartPage } = useContext(HelpContext);
  const { users, auth, user } = useContext(UserContext);
  const { folderId, projectId } = useParams();
  const [modalType, setModalType] = useState<string>('');
  const [selectedItemsTemp, setSelecetedItemsTemp] = useState<FolderItem[]>([]);
  const [shareModalVisible, setShareModalVisible] = useState<boolean>(false);
  const [selectedShare, setSelectedShare] = useState<OptionDefinition[]>([]);
  const [shareDocLoading, setShareDocLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleReset = () => {
    setNewFolderModalVisible(false);
    setFolderName('');
  };

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(currentProjectFolders, {
    filtering: {
      empty: <Box>Nothing</Box>,
      noMatch: <Box>No matches</Box>
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const selectedFolderPermissions = () => {
    const permissionsList = selectedItemsTemp![0]!.permissions;
    const tempOptionsList = permissionsList.map((member) => {
      return {
        label: member.name,
        value: member.sub
      };
    });
    setSelectedShare([...tempOptionsList]);
  };

  // const { selectedItems } = collectionProps;
  useEffect(() => {
    if (
      selectedProjects &&
      selectedProjects.length > 0 &&
      selectedItemsTemp &&
      selectedItemsTemp.length > 0
    ) {
      setSelectedFolder(selectedItemsTemp);
      setCurrentFolder(selectedItemsTemp[0]._id);
      selectedFolderPermissions();
      navigate(
        `/app/projects/${selectedProjects[0]._id}/folder/${selectedItemsTemp[0]._id}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemsTemp, selectedProjects]);

  const setInfo = () => {
    if (!folderId) {
      setSelectedFolder([]);
    } else {
      const folderSelected = currentProjectFolders.filter(
        (ele) => ele._id === folderId
      );
      setSelecetedItemsTemp(folderSelected);
    }
    if (projectId) {
      setProjectIdContext(projectId);
    }
  };

  const canDeleteFolder = useMemo(() => {
    if (selectedItemsTemp?.length > 0) {
      const folderDocs = allDocuments.filter(
        (ele) => ele.folder === selectedItemsTemp[0]?._id
      );
      console.log('no of docs', folderDocs.length);
      if (folderDocs.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemsTemp]);
  useEffect(() => {
    setInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId, selectedProjects]);
  useEffect(() => {
    console.log('folderId in params', folderId);
  }, [JSON.stringify(folderId)]);

  const countDocuments = (folderId: string) => {
    const thisFolderDocuments = allDocuments.filter(
      (ele) => ele.folder === folderId
    );
    return thisFolderDocuments.length;
  };

  const handleSave = () => {
    if (modalType === 'CREATE') {
      createNewFolder();
    }
    if (modalType === 'EDIT') {
      updateFolder(selectedFolders[0]._id);
    }
    getAllDocs();
  };

  const handleShareModalCancel = () => {
    setShareDocLoading(false);
    setShareModalVisible(false);
    setSelectedShare([]);
  };

  const updateShare = async () => {
    if (!selectedItemsTemp) return;
    try {
      setShareDocLoading(true);
      console.log('original', selectedItemsTemp[0].permissions);
      console.log('new', selectedShare);
      const getChanges = manageShare(
        selectedItemsTemp[0].permissions.map((ele) => {
          return { value: ele.sub, label: ele.name };
        }),
        selectedShare
      );
      const reqBody = {
        _id: selectedItemsTemp[0]._id,
        projectId: selectedItemsTemp[0].project,
        remove: getChanges.removedItems.map((ele) => {
          return {
            sub: ele.value,
            action: 'view',
            name: ele.label
          };
        }),
        add: getChanges.addedItems.map((ele) => {
          return {
            sub: ele.value,
            action: 'view',
            name: ele.label
          };
        })
      };
      const response = await axios.post(
        `${process.env.REACT_APP_KORU_API}/share?type=folder`,
        reqBody,
        auth
      );
      console.log('reqBody', reqBody);
      // update the specific documents permissions
      const updatedItem = response.data.result;
      const tempDocs = allDocuments;
      const specificDocIndex = tempDocs.findIndex(
        (ele) => ele._id === updatedItem._id
      );
      if (specificDocIndex !== -1) {
        tempDocs[specificDocIndex].permissions = updatedItem.permissions;
      }
      setAllDocuments([...tempDocs]);

      // console.log('result', response.data);
      getAllDocs();
      setShareDocLoading(false);
      setShareModalVisible(false);
      setSelectedShare([]);
      setSelecetedItemsTemp([]);
    } catch (e) {
      const error = e as Error;
      console.log('Update error ', error.message);
      setShareDocLoading(false);
    }
  };
  const handleDeleteFolder = async () => {
    try {
      deleteFolder(selectedFolders[0]._id);
      getAllDocs();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <BackAndHomeButtons showBackButton={true} />
      <Container
        header={
          <Header
            description="Select a folder to get started"
            actions={
              <SpaceBetween direction="horizontal" size="s">
                {viewed[tourNameEmun.FOLDER] ||
                Cookies.get(tourNameEmun.FOLDER) ? (
                  <Button
                    onClick={() => {
                      setTourType(tourNameEmun.FOLDER);
                      setShowTourStartPage(true);
                    }}
                  >
                    Tour
                  </Button>
                ) : null}
                <Button
                  data-tour-id="shareFoldersButton"
                  iconName="share"
                  disabled={!(selectedFolders.length === 1)}
                  variant="icon"
                  onClick={() => setShareModalVisible(true)}
                />
                <Button
                  onClick={() => handleDeleteFolder()}
                  disabled={canDeleteFolder}
                  loading={deleteLoadingFolder}
                >
                  Delete
                </Button>
                <Button
                  data-tour-id="editFodlersButton"
                  disabled={selectedFolders.length !== 1}
                  onClick={() => {
                    setNewFolderModalVisible(true);
                    setModalType('EDIT');
                    setFolderName(selectedFolders[0].folderName);
                  }}
                  // disabled={!selectedFolder._id ? true : false}
                >
                  Edit Folder
                </Button>
                <Button
                  data-tour-id="createFoldersButton"
                  onClick={() => {
                    setNewFolderModalVisible(true);
                    setModalType('CREATE');
                  }}
                >
                  Create Folder
                </Button>
                {/* <Button
                  variant="icon"
                  iconName="arrow-left"
                  onClick={() => {
                    // setViewFolders(false);
                    setCurrentFolder('');
                    setSelectedProjects([]);
                    setSelectedFolder([]);
                    navigate('/app/projects');
                  }}
                ></Button> */}
              </SpaceBetween>
            }
          >
            Searchable Folders
          </Header>
        }
      >
        <Table
          data-tour-id="folderTable"
          header={`${selectedProjects[0]?.projectName}`}
          {...collectionProps}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          columnDefinitions={[
            {
              id: 'title',
              header: 'Folder Name',
              cell: (item: FolderItem) => item.folderName,
              sortingField: 'documentFileName',
              maxWidth: '400px'
            },
            // {
            //   id: 'id',
            //   header: ' Folder id',
            //   cell: (item: FolderItem) => item._id
            // },
            {
              id: 'description',
              header: 'Description',
              cell: (item) => item.folderDescription
            },
            {
              id: 'docCount',
              header: '# Documents',
              cell: (item: FolderItem) => countDocuments(item._id)
            },
            {
              id: 'permission',
              header: 'Permissions',
              cell: (item: FolderItem) =>
                `${item.permissions.map((ele) => ele.name).join(' , ')}`
            }
          ]}
          selectionType="multi"
          selectedItems={selectedItemsTemp}
          onSelectionChange={({ detail }) => {
            setSelecetedItemsTemp(detail.selectedItems);
          }}
        />
      </Container>
      <Modal
        visible={newFolderModalVisible}
        onDismiss={() => handleReset()}
        header={modalType === 'EDIT' ? 'Edit Folder Name' : 'Create New Folder'}
        footer={
          <SpaceBetween size={'s'} direction="horizontal" alignItems="end">
            <Button onClick={() => handleReset()}>Cancel</Button>
            <Button onClick={() => handleSave()} loading={createFolderLoading}>
              Save
            </Button>
          </SpaceBetween>
        }
      >
        <FormField label="Folder Name">
          <Input
            value={folderName}
            onChange={(event) => setFolderName(event.detail.value)}
          />
        </FormField>
        <FormField label="Folder Description">
          <Input
            value={folderDescription}
            onChange={(event) => setFolderDescription(event.detail.value)}
          />
        </FormField>
      </Modal>
      <Modal
        size="large"
        visible={shareModalVisible}
        onDismiss={() => handleShareModalCancel()}
        header={'Share Folder'}
        footer={
          <SpaceBetween size={'s'} direction="horizontal" alignItems="end">
            <Button onClick={() => handleShareModalCancel()}>Cancel</Button>
            <Button onClick={() => updateShare()} loading={shareDocLoading}>
              Update Permissions
            </Button>
          </SpaceBetween>
        }
      >
        <div
          style={{
            height: '40vh'
          }}
        >
          <p>
            Sharing the folder will update permissions on all the documents in
            the folder
          </p>
          <Multiselect
            onChange={({ detail }) =>
              setSelectedShare([...detail.selectedOptions])
            }
            selectedOptions={selectedShare}
            options={users.map((ele) => {
              return {
                label: ele.name,
                value: ele.sub,
                disabled: ele.sub === user.sub
              };
            })}
          ></Multiselect>
        </div>
      </Modal>
    </>
  );
};
