import { useState, useEffect, useContext, useMemo } from 'react';
import {
  Table,
  Container,
  Button,
  SpaceBetween,
  Header,
  Box,
  Spinner,
  ProgressBar,
  Icon,
  Modal,
  RadioGroup,
  Select,
  Pagination,
  Multiselect
} from '@cloudscape-design/components';

// models
import {
  documentTableItem,
  FolderItem,
  KBOptions,
  Projects,
  tourNameEmun
} from '../models/interfaces';
import { useCollection } from '@cloudscape-design/collection-hooks';
// helpers
// dummy data
import {
  useNavigate,
  useSearchParams,
  useLocation,
  useParams
} from 'react-router-dom';
import axios from 'axios';
import { DocumentContext } from '../context/documentContext';
import { UserContext } from '../context/authContext';
import { ProjectForm } from './Components/projectForm';
import { getProjectType } from '../helpers/projectType';
import { ProjectContext } from '../context/projectContext';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { manageShare } from '../helpers/manageShareHelpers';
import { HelpContext } from '../context/helpContext';
import { BackAndHomeButtons } from './Components/help/backAndHomeButtons';
import Cookies from 'js-cookie';

export const ProjectTableView = (props: {}) => {
  const navigate = useNavigate();
  const {
    mainProjects,
    setMainProjects,
    // getAllProjects,
    selectedProjects,
    setSelectedProjects,
    setSelectedFolder,
    setProjectIdContext
  } = useContext(ProjectContext);
  const { auth, user, users } = useContext(UserContext);
  const { allDocuments, getAllDocs } = useContext(DocumentContext);
  const {
    setShowTourEvents,
    showTourEvents,
    setShowTourStartPage,
    viewed,
    setTourType
  } = useContext(HelpContext);

  const { projectId } = useParams();

  const [folderModal, setFolderModal] = useState<boolean>(false);
  const [addProjectLoading, setAddProjectLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [shareModalVisible, setShareModalVisible] = useState<boolean>(false);
  const [selectedShare, setSelectedShare] = useState<OptionDefinition[]>([]);
  const [shareDocLoading, setShareDocLoading] = useState<boolean>(false);

  const setInfoFromUrl = () => {
    if (projectId) {
      setProjectIdContext(projectId);
    }
  };

  useEffect(() => {
    setInfoFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canDeleteProject = useMemo(() => {
    if (selectedProjects.length > 0) {
      const projectDocs = allDocuments.filter(
        (ele) => ele.project === selectedProjects[0]?._id
      );
      console.log('no of docs', projectDocs.length);
      if (projectDocs.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjects]);

  const createNewProject = async (
    projectName: string,
    projectDescription: string,
    projectType: string
  ) => {
    try {
      setAddProjectLoading(true);
      const createProject = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=createNewProject`,
        {
          projectName,
          projectDescription,
          projectType,
          owner: user.name
        },
        auth
      );
      // getAllProjects('create new ptv 108');
      getAllDocs();

      setAddProjectLoading(false);
      setFolderModal(false);
    } catch (e) {
      const error = e as Error;
      console.log('Error', error.message);
    }
  };

  // useEffect(() => {
  //   getAllProjects('auth change ptv 140');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [auth]);

  const deleteProject = async () => {
    try {
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=deleteProject`,
        {
          _id: selectedProjects[0]._id
        },
        auth
      );
      setSelectedProjects([]);
      // getAllProjects('delet project ptv 154');
      getAllDocs();
    } catch (e) {
      const error = e as Error;
      console.log('Error', error.message);
    }
  };

  useEffect(() => {
    if (selectedProjects.length === 0) {
      setSelectedFolder([]);
    } else {
      selectedProjectPermissions();
    }
  }, [selectedProjects]);

  // sharing functions

  const handleShareModalCancel = () => {
    setShareDocLoading(false);
    setShareModalVisible(false);
    setSelectedShare([]);
  };

  const updateShare = async () => {
    if (!selectedProjects) return;
    try {
      setShareDocLoading(true);
      console.log('original', selectedProjects[0].permissions);
      console.log('new', selectedShare);
      const getChanges = manageShare(
        selectedProjects[0].permissions.map((ele) => {
          return { value: ele.sub, label: ele.name };
        }),
        selectedShare
      );
      console.log('changes', getChanges);
      const reqBody = {
        _id: selectedProjects[0]._id,
        remove: getChanges.removedItems.map((ele) => {
          return {
            sub: ele.value,
            action: 'view',
            name: ele.label
          };
        }),
        add: getChanges.addedItems.map((ele) => {
          return {
            sub: ele.value,
            action: 'view',
            name: ele.label
          };
        })
      };
      const response = await axios.post(
        `${process.env.REACT_APP_KORU_API}/share?type=project`,
        reqBody,
        auth
      );
      console.log('reqBody', reqBody);
      // update the specific documents permissions
      const updatedItem = response.data.result;
      const tempProjects = mainProjects;
      const specificProjectIndex = tempProjects.findIndex(
        (ele) => ele._id === updatedItem._id
      );
      if (specificProjectIndex !== -1) {
        tempProjects[specificProjectIndex].permissions =
          updatedItem.permissions;
      }
      setMainProjects([...tempProjects]);

      // console.log('result', response.data);
      getAllDocs();
      setShareDocLoading(false);
      setShareModalVisible(false);
      setSelectedShare([]);
      setSelectedProjects([]);
    } catch (e) {
      const error = e as Error;
      console.log('Update error ', error.message);
      setShareDocLoading(false);
    }
  };

  const selectedProjectPermissions = () => {
    const permissionsList = selectedProjects[0]!.permissions;
    const tempOptionsList = permissionsList.map((member) => {
      return {
        label: member.name,
        value: member.sub
      };
    });
    setSelectedShare([...tempOptionsList]);
  };

  const emptyState = () => {
    if (mainProjects.length === 0 && !fetching) {
      return (
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No Projects</b>
            <Button onClick={() => setFolderModal(true)}>
              Create Project To Start
            </Button>
          </SpaceBetween>
        </Box>
      );
    }
  };

  const {
    items,
    actions,
    filteredItemsCount,
    // collectionProps,
    filterProps,
    paginationProps
  } = useCollection(mainProjects, {
    filtering: {
      empty: <Box>Nothing</Box>,
      noMatch: <Box>No matches</Box>
    },
    pagination: { pageSize: 15 },
    sorting: {},
    selection: {}
  });

  return (
    <>
      <BackAndHomeButtons showBackButton={false} />
      <Container
        data-tour-id="projects-overview"
        header={
          <Header
            variant="h1"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                {/* <Button onClick={() => fixProjects()}>Fix</Button> */}
                {viewed[tourNameEmun.PROJECT] ||
                Cookies.get(tourNameEmun.PROJECT) ? (
                  <Button
                    onClick={() => {
                      setTourType(tourNameEmun.PROJECT);
                      setShowTourStartPage(true);
                    }}
                  >
                    Tour
                  </Button>
                ) : null}
                <Button
                  iconName="share"
                  variant="icon"
                  disabled={!(selectedProjects.length > 0)}
                  onClick={() => setShareModalVisible(true)}
                  data-tour-id="projectShareButton"
                />
                <Button
                  disabled={!(selectedProjects && selectedProjects.length > 0)}
                  onClick={() => {
                    // setViewFolder(true);
                    // setSearchParams(`projectId=${selectedProjects[0]._id}`);
                    navigate(
                      `/app/projects/${selectedProjects[0]._id}/folder/`
                    );
                    // setCurrentFolder('');
                  }}
                  data-tour-id="projectViewButton"
                >
                  View Project
                </Button>
                <Button
                  onClick={() => deleteProject()}
                  disabled={canDeleteProject}
                >
                  Delete Project
                </Button>
                <Button
                  data-tour-id="projectButton"
                  onClick={() => setFolderModal(true)}
                  loading={addProjectLoading}
                >
                  Create Project
                </Button>
              </SpaceBetween>
            }
          >
            Projects
          </Header>
        }
      >
        <div style={{ overflow: 'scroll', scrollbarWidth: 'none' }}>
          <Table
            // onSelectionChange={({ detail }) =>
            //   setSelectedItemsLocal(detail.selectedItemsLocal)
            // }
            // {...collectionProps}
            contentDensity="compact"
            items={items}
            selectionType="single"
            pagination={<Pagination {...paginationProps} />}
            columnDefinitions={[
              {
                id: 'name',
                header: 'Project Name',
                cell: (item: Projects) => item.projectName,
                sortingField: 'documentFileName',
                maxWidth: '400px'
              },
              {
                id: 'description',
                header: 'Description',
                cell: (item: Projects) => item.projectDescription,
                maxWidth: '200px'
              },
              {
                id: 'owner',
                header: 'Project Owner',
                cell: (item: Projects) => item.owner,
                sortingField: 'owner',
                maxWidth: '200px'
              },
              {
                id: 'type',
                header: <div data-tour-id="project-type">Type</div>,
                cell: (item: Projects) => getProjectType(item.projectType),
                maxWidth: '200px'
              },
              {
                id: 'date',
                header: 'Date Created',
                cell: (item: Projects) =>
                  new Date(item.createdAt).toLocaleDateString(),
                sortingField: 'date',
                sortingComparator(a, b) {
                  return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  );
                }
              },
              {
                id: 'permission',
                header: <div data-tour-id="shared">Shared</div>,
                cell: (item: Projects) =>
                  `${item.permissions.map((ele) => ele.name).join(' ')}`
              }
            ]}
            loadingText="Loading resources"
            empty={emptyState()}
            onSelectionChange={({ detail }) => {
              setSelectedProjects(detail.selectedItems);
            }}
            selectedItems={selectedProjects}
          />
        </div>
      </Container>
      {/* show the new project modal */}
      <Modal
        data-tour-id="projectModal"
        visible={folderModal || showTourEvents['projectModal']}
        onDismiss={() => {
          setFolderModal(false);
          setShowTourEvents({ ...showTourEvents, projectModal: false });
        }}
      >
        <ProjectForm
          createNewProject={createNewProject}
          setFolderModal={setFolderModal}
        />
      </Modal>
      <Modal
        size="large"
        visible={shareModalVisible}
        onDismiss={() => handleShareModalCancel()}
        header={'Share Project'}
        footer={
          <SpaceBetween size={'s'} direction="horizontal" alignItems="end">
            <Button onClick={() => handleShareModalCancel()}>Cancel</Button>
            <Button onClick={() => updateShare()} loading={shareDocLoading}>
              Update Permissions
            </Button>
          </SpaceBetween>
        }
      >
        <div
          style={{
            height: '40vh'
          }}
        >
          <p>
            Sharing at the Project level will share all folders and documents
          </p>
          <Multiselect
            onChange={({ detail }) =>
              setSelectedShare([...detail.selectedOptions])
            }
            // virtualScroll
            // expandToViewport
            selectedOptions={selectedShare}
            options={users.map((ele) => {
              return {
                label: ele.name,
                value: ele.sub,
                disabled:
                  (ele.sub === user.sub && !user.isAdmin) || !user.isAdmin
              };
            })}
          ></Multiselect>
        </div>
      </Modal>
    </>
  );
};
