import { Button, Checkbox, Modal } from '@cloudscape-design/components';
import { useContext } from 'react';
import { HelpContext } from '../../../context/helpContext';
import { StartPageLine } from './tourStartPageLine';
import Cookies from 'js-cookie';
import { landingPages } from './helpTours';

export const TourStartModal = () => {
  const {
    setHelpModalVisible,
    showTourStartPage,
    setShowTourStartPage,
    tourType,
    setViewed,
    viewed,
    doNotShowAgain,
    setDoNotShowAgain
  } = useContext(HelpContext);
  const handleStartTour = () => {
    setHelpModalVisible(true);
    setShowTourStartPage(false);
    setViewed({ ...viewed, [`${tourType}`]: true });
    if (doNotShowAgain) {
      Cookies.set(`${tourType}`, JSON.stringify(true));
    }
  };
  const handleDismiss = () => {
    if (doNotShowAgain) {
      Cookies.set(`${tourType}`, JSON.stringify(true));
    }
    setShowTourStartPage(false);
    setDoNotShowAgain(false);
  };
  return (
    <>
      {tourType && showTourStartPage ? (
        <Modal
          onDismiss={() => handleDismiss()}
          visible={showTourStartPage}
          size="large"
          header={landingPages[`${tourType}`].title}
        >
          <div style={{ minHeight: '20vh' }}>
            <p>{landingPages[`${tourType}`].description}</p>
            {landingPages[`${tourType}`].steps.map((ele, index) => {
              return (
                <StartPageLine
                  index={(index + 1).toString()}
                  title={ele.title}
                  description={ele.description}
                />
              );
            })}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Button onClick={() => handleStartTour()}>Start Tour</Button>
            <Checkbox
              onChange={({ detail }) => setDoNotShowAgain(detail.checked)}
              checked={doNotShowAgain}
            >
              {`Do not show ${tourType} tour again`}
            </Checkbox>
          </div>
        </Modal>
      ) : null}
    </>
  );
};
