import {
  Button,
  Cards,
  ExpandableSection
} from '@cloudscape-design/components';
import { DocumentContext } from '../../context/documentContext';
import { useContext, useEffect, useState } from 'react';
import { QandASentence } from '../../models/interfaces';

interface Props {
  setLocalAnswerString: (arg: string) => void;
  localAnswerString: string;
  handleAddToAnswer: (arg: string) => void;
  qaOpen: boolean;
  setQAOpen: (arg: boolean) => void;
}

export const QandAResponses = (props: Props) => {
  const {
    sentences,
    selectedItems,
    selectedItemsQAResults,
    qaSelectedItems,
    setQASelectedItems
  } = useContext(DocumentContext);

  useEffect(() => {
    if (selectedItems.length > 0) {
      const curentItem = selectedItems[0] as unknown as QandASentence;
      const currentSentenceQAItems = curentItem.qaSentences.map((ele) => ele);
      setQASelectedItems(currentSentenceQAItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    // <Container header={<Header>Q & A Responses</Header>}>
    <ExpandableSection
      data-tour-id="qandaSearchResults"
      variant="container"
      headerText={`Q&A Results - (${selectedItemsQAResults.length})`}
      expanded={props.qaOpen}
      onChange={() => props.setQAOpen(!props.qaOpen)}
      // headerCounter={`${selectedItemsQAResults.length}`}
    >
      <Cards
        cardsPerRow={[{ cards: 1 }]}
        items={selectedItemsQAResults}
        onSelectionChange={({ detail }) => {
          console.log('deatil changed', detail);
          setQASelectedItems(detail?.selectedItems ?? []);
        }}
        selectedItems={qaSelectedItems}
        trackBy={'_id'}
        selectionType="multi"
        cardDefinition={{
          header: (item) => (
            <p style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
              {item.text}
            </p>
          ),
          sections: [
            {
              id: 'answer',
              header: 'Answer',
              content: (item) => (
                <>
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    onClick={() => props.handleAddToAnswer(item.answer)}
                  ></Button>
                  <span>{item.answer}</span>
                </>
              )
            }
          ]
        }}
      ></Cards>
    </ExpandableSection>
    // </Container>
  );
};
