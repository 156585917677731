import * as React from 'react';
import BreadcrumbGroup, {
  BreadcrumbGroupProps
} from '@cloudscape-design/components/breadcrumb-group';
import { ProjectContext } from '../../context/projectContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DocumentContext } from '../../context/documentContext';
import { Button, Icon, SpaceBetween } from '@cloudscape-design/components';
import { HelpContext } from '../../context/helpContext';
import { tourNameEmun } from '../../models/interfaces';
import { DocumentViewButtons } from '../Components/help/backAndHomeButtons';

export const DocumentBreadcrumbs = () => {
  const { mainProjects, currentProjectFolders } = useContext(ProjectContext);
  const { currentDoc, allDocuments } = useContext(DocumentContext);
  const { setHelpModalVisible, setTourType } = useContext(HelpContext);
  const [trailArray, setTrailArray] = useState<
    { text: string; href: string }[]
  >([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  const createTrail = () => {
    const projectId = queryParameters.get('projectId');
    const type = queryParameters.get('type');
    let folderFilterId: string;
    if (type === 'aggregated') {
      const docs = queryParameters.get('documentId')!.split(',')!;
      const currentLocalDoc = allDocuments.filter((ele) => ele._id === docs[0]);
      folderFilterId = currentLocalDoc[0]?.folder;
    } else {
      folderFilterId = currentDoc.folder;
    }
    if (!currentDoc || !projectId || !mainProjects) return;
    if (currentDoc && projectId && mainProjects.length > 0) {
      const currentProject = mainProjects?.filter(
        (ele) => ele._id === projectId
      )[0];
      const { projectName, _id } = currentProject;
      const currentFolder = currentProjectFolders.filter(
        (ele) => ele._id === folderFilterId
      );
      const tempTrail = [
        // { text: 'Home', href: '/' },
        {
          // text: `${projectName}`,
          text: `Back to Project`,
          href: `/app/projects/${_id}`
        }
      ];
      tempTrail.push({
        // text: `${currentFolder[0]?.folderName ? currentFolder[0].folderName : ''}`,
        text: `Back to Folder`,
        href: `/app/projects/${_id}/folder/${currentFolder[0]?._id}`
      });
      if (type === 'single') {
        tempTrail.push({
          text: `${currentDoc.documentFileName}`,
          href: ``
        });
      } else {
        tempTrail.push({
          text: ``,
          href: ``
        });
      }
      setTrailArray(tempTrail);
    }
  };
  /// /app/projects/670ce87f21ff7f3e4d2cea56/folder/670ce87f21ff7f3e4d2cea5c
  useEffect(() => {
    createTrail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDoc, location.pathname, queryParameters.get('projectId')]);
  useEffect(() => {
    createTrail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClick = (
    e: CustomEvent<BreadcrumbGroupProps.ClickDetail<BreadcrumbGroupProps.Item>>
  ) => {
    e.preventDefault();
    console.log('e breadcrumb clicked', e);
    navigate(e.detail.href);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        // width: '100%'
        maxHeight: '2%'
      }}
    >
      <DocumentViewButtons showBackButton={true} />
      {trailArray.length > 0 ? (
        <BreadcrumbGroup
          items={trailArray}
          ariaLabel="Breadcrumbs"
          onClick={(e) => handleClick(e)}
        />
      ) : null}

      <Button
        onClick={() => {
          setTourType(tourNameEmun.DOCUMENT_VIEW);
          setHelpModalVisible(true);
        }}
      >
        Show Tour
      </Button>
    </div>
  );
};
