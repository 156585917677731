import { Step } from 'react-joyride';
import { ILandingPage, tourNameEmun } from '../../../models/interfaces';
import { TourWithChildren, TourWithTextAndLink } from './mainTour';
const childernStyleP = {
  fontSize: '12px',
  lineHeight: '16px',
  textAlign: 'left'
};
export const steps: { [key: string]: Step[] } = {
  [tourNameEmun.DOCUMENT_VIEW]: [
    {
      target: '[data-tour-id="documentView"]',
      content: (
        <TourWithTextAndLink
          text="KORU automatically identifies and categorizes key information in your documents, helping you quickly find what matters."
          title="Smart Document Analysis"
        />
      ),
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="exploreDocument"]',
      content: (
        <TourWithTextAndLink
          text="Quickly navigate to specific topics like scope of work, resources, or obligations. Each category highlights relevant text across your document."
          title="Focus On What Matters"
        />
      ),
      placement: 'left',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="unclassifiedButton"]',
      content: (
        <TourWithTextAndLink
          text="Text highlighted in yellow needs your expertise to categorize. Your input helps ensure nothing important is missed."
          title="Review & Refine"
        />
      ),
      placement: 'top'
    },
    {
      target: '[data-tour-id="docResetButton"]',
      content: (
        <TourWithTextAndLink
          text="Reset highlighting to start fresh or return to uncategorized text."
          title="Clear Your View"
        />
      ),
      placement: 'bottom'
    },
    {
      target: '[data-tour-id="docDownloadButton"]',
      content: (
        <TourWithTextAndLink
          text="Generate focused reports by category to share specific requirements, obligations, or resources with team members."
          title="Share Key Information"
        />
      ),
      placement: 'bottom'
    },
    {
      target: '[data-tour-id="docCategorizeComponent"]',
      content: (
        <TourWithTextAndLink
          text="Select any text to recategorize, add notes, or mark changes. Track team input and maintain a clear history of document reviews."
          title="Collaborate on Reviews"
        />
      ),
      placement: 'left'
    },
    {
      target: '[data-tour-id="docStarButton"]',
      content: (
        <TourWithTextAndLink
          text="Star key sentences to create your own highlights. Add notes to explain why they matter."
          title="Mark Important Text"
        />
      ),
      placement: 'left'
    },
    {
      target: '[data-tour-id="docStarButtonViewer"]',
      content: (
        <TourWithTextAndLink
          text="View all starred text at once to focus on critical information you identified."
          title="Quick Access to Key Points"
        />
      ),
      placement: 'left'
    },
    {
      target: '[data-tour-id="docConnectInformation"]',
      content: (
        <TourWithTextAndLink
          text="Connect related requirements across documents to maintain traceability as obligations evolve."
          title="Track Related Information"
          // link={`${process.env.REACT_APP_BASE_URL}/app/videos?card=card5`}
        />
      ),
      placement: 'left'
    }
  ],
  [tourNameEmun.CONNECT]: [
    {
      target: '[data-tour-id="docConnectInformation"]',
      content: (
        <TourWithTextAndLink
          text="Connect Information to other document or search the knowledge base"
          title="Connect Information"
        />
      ),
      placement: 'top',
      disableBeacon: true,
      data: 'tab1'
    },
    {
      target: '[data-tour-id="docConnectMapping"]',

      content: (
        <TourWithChildren>
          <div
            style={{
              display: 'flex',
              padding: '0 0',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <h4>Map Project Relationships</h4>
            <p
              style={{
                fontSize: '12px',
                lineHeight: '16px',
                textAlign: 'left',
                paddingLeft: '2%'
              }}
            >
              Link related requirements within your project documents to track
              dependencies and changes across your project lifecycle.
            </p>
            <h4>Leverage Past Experience'</h4>
            <p
              style={{
                fontSize: '12px',
                lineHeight: '16px',
                textAlign: 'left',
                paddingLeft: '2%'
              }}
            >
              Find similar requirements in your knowledge base to ensure
              consistency and reuse proven approaches.
            </p>
            <h4>Track Document Evolution</h4>
            <p
              style={{
                fontSize: '12px',
                lineHeight: '16px',
                textAlign: 'left',
                paddingLeft: '2%'
              }}
            >
              See which documents reference this requirement to understand how
              it has changed or been interpreted over time.
            </p>
            <h4>Add Supporting Context</h4>
            <p
              style={{
                fontSize: '12px',
                lineHeight: '16px',
                textAlign: 'left',
                paddingLeft: '2%'
              }}
            >
              Link to supplementary information like technical specifications or
              regulatory requirements that inform this obligation.
            </p>
            <h4>Build Your Knowledge Network</h4>
            <p
              style={{
                fontSize: '12px',
                lineHeight: '16px',
                textAlign: 'left',
                paddingLeft: '2%'
              }}
            >
              ave connections to create a searchable web of related requirements
              that grows with your project.
            </p>
          </div>
        </TourWithChildren>
      ),
      placement: 'left',
      data: 'tab1'
    }
    // {
    //   target: '[data-tour-id="docConnectInformation"]',
    //   content:
    //     'Connect Information to other document or search the knowledge base',
    //   placement: 'top',
    //   disableBeacon: true,
    //   data: 'tab1'
    // }
    // {
    //   target: '[data-tour-id="docKBMapping"]',
    //   content: 'This will be a description of the steps',
    //   placement: 'left',
    //   data: 'tab2'
    // },
    // {
    //   target: '[data-tour-id="docReferencedBy"]',
    //   content: 'This will be a description of the steps',
    //   placement: 'left',
    //   data: 'tab3'
    // }
  ],
  [tourNameEmun.PROJECT]: [
    {
      target: '[data-tour-id="projects-overview"]',
      content: (
        <TourWithTextAndLink
          text="Welcome to your Projects Dashboard. This is where you'll manage all your document workspaces and information requests."
          title="Start Here."
        />
      ),
      placement: 'center',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="projectViewButton"]',
      content: (
        <TourWithTextAndLink
          text="Create project structure to organize content"
          title="Organize Project"
        />
      ),
      placement: 'left',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="projectButton"]',
      content: (
        <TourWithTextAndLink text="Start here to create a new project workspace for document analysis or answering questionnaires." />
      ),

      placement: 'bottom-start',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="projectShareButton"]',
      content: (
        <TourWithTextAndLink
          text="Add team members to the project."
          title="Collaborate"
        />
      ),
      placement: 'left',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="project-type"]',
      content: (
        <TourWithTextAndLink
          text="Projects can be Workspaces for document analysis or Requests for information gathering - choose based on your needs."
          title="Choose Workspace Type"
        />
      ),
      placement: 'right'
    },
    {
      target: '[data-tour-id="shared"]',
      content: (
        <TourWithTextAndLink
          text="Control team project team membership."
          title="Review Access"
        />
      ),
      placement: 'right'
    }
  ],
  [tourNameEmun.FOLDER]: [
    {
      target: '[data-tour-id="createFoldersButton"]',
      content: (
        <TourWithTextAndLink
          text="Group related documents into folders to narrow your search when looking for specific information - search within a folder, across the project, or your entire knowledge base."
          title="Define Your Search Scope"
        />
      ),
      placement: 'left',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="editFoldlersButton"]',
      content: (
        <TourWithTextAndLink
          text="Add clear descriptions to folders to help team members understand what documents belong where, ensuring consistent organization."
          title="Guide Your Team"
        />
      ),
      placement: 'left',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="shareFoldersButton"]',
      content: (
        <TourWithTextAndLink
          text="Protect sensitive information by managing folder-level access, giving team members access to only the documents they need."
          title="Control Information Access"
        />
      ),
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="folderTable"]',
      content: (
        <TourWithTextAndLink
          text="Use the default folders to organize typical project documents or create/edit folders."
          title="Topical Folder Structure"
        />
      ),
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="actionsButton"]',
      title: 'Manage Documents & Analysis',
      content: (
        <TourWithChildren>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>Select documents to:</p>
            <p>
              <b>Reorganize Collections</b>
            </p>
            <p>Move documents between folders as your project evolves</p>
            <p>
              <b>Delete</b>
            </p>
            <p>Delete the selected document</p>
            <p>
              <b>Start Analysis</b>
            </p>
            <p>Upload new documents for automated obligation tracking</p>
          </div>
        </TourWithChildren>
      ),
      placement: 'left',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="viewSingleDocumentButton"]',
      content: (
        <TourWithTextAndLink
          text="Open individual documents to review obligations, add insights, and track requirements."
          title="Deep Dive Analysis"
        />
      ),
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="viewMultipleDocumentButton"]',
      content: (
        <TourWithTextAndLink
          text="Review multiple documents together to identify related obligations and ensure requirement consistency."
          title="Compare Documents Side by Side"
        />
      ),
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="addToKBButton"]',
      content: (
        <TourWithTextAndLink
          text="Make documents searchable across projects by adding them to your knowledge base, creating a valuable resource for future work."
          title="Build Your Reference Library"
        />
      ),
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="reportsButton"]',
      content: (
        <TourWithTextAndLink
          text="Share targeted reports on tasks, requirements, obligations, or specifications to ensure all stakeholders are working from the latest information."
          title="Keep Teams Aligned"
        />
      ),
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="docShareButton"]',
      content: (
        <TourWithTextAndLink
          text="Share specific documents with team members while maintaining control over sensitive information"
          title="Collaborate Securely"
        />
      ),
      placement: 'bottom',
      disableBeacon: true
    }
  ],
  [tourNameEmun.SEARCH]: [
    {
      target: '[data-tour-id="searchComponent"]',
      content: (
        <TourWithTextAndLink
          text="Access your organizations library of vetted documents and past questionnaire responses to inform current decisions."
          title="Powerful Knowledge Search"
        />
      ),
      placement: 'center',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="searchScope"]',
      placement: 'bottom',
      content: (
        <TourWithTextAndLink
          text="Choose to search within specific knowledge bases or questionnaire responses to find exactly what you need."
          title="Target Your Search"
        />
      )
    },
    {
      target: '[data-tour-id="searchResults"]',
      content: (
        <TourWithTextAndLink
          text="Get organized results showing obligations, financial details, and timelines - with direct links to source documents and related discussions."
          title="Structured Insights"
        />
      ),
      placement: 'left'
    }
    // {
    //   target: '[data-tour-id="searchContext"]',
    //   title: 'Full Context at a Glance',
    //   content:
    //     'See how information connects across documents, including team notes and related requirements.',
    //   placement: 'left'
    // },
    // {
    //   target: '[data-tour-id="similarityScore"]',
    //   title: 'Understand Relevance',
    //   content:
    //     'Similarity scores help you quickly identify the most relevant matches to your query.',
    //   placement: 'right'
    // }
  ],
  [tourNameEmun.QANDA_MODAL]: [
    {
      target: '[data-tour-id="questionHeader"]',
      content: (
        <TourWithTextAndLink
          text="Each question is highlighted for easy reference. Yellow highlighting indicates this question needs your input."
          title="Review Question Context"
        />
      ),
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '[data-tour-id="qandaSearchResults"]',
      content: (
        <TourWithTextAndLink
          text="Review how similar questions were answered in other documents. Build consistent responses by leveraging proven answers."
          title="Learn From Past Answers"
        />
      ),
      placement: 'right'
    },
    {
      target: '[data-tour-id="formatingBar"]',
      content: (
        <TourWithTextAndLink
          text="Use text formatting to emphasize key points and create clear, professional answers that maintain consistency across your documents."
          title="Format Your Response"
        />
      ),
      placement: 'left'
    },
    {
      target: '[data-tour-id="researchButton"]',
      content: (
        <TourWithTextAndLink
          text="Find relevant information from your knowledge base to validate your response and maintain answer quality."
          title="Support Your Answer"
        />
      ),
      placement: 'top'
    },
    {
      target: '[data-tour-id="notesSection"]',
      // title: 'QANDA',
      // content: 'Select from previous answers to build answer.',
      placement: 'left',
      content: (
        <TourWithTextAndLink
          text="Add notes to explain your answer choices and help team members understand the context of your decisions."
          title="Document Your Reasoning"
        />
      ),
      disableBeacon: true
    },
    {
      target: '[data-tour-id="qandaSave"]',
      content: (
        <TourWithTextAndLink
          text="Save your progress to return later or share with team members for review and collaboration."
          title="Preserve Your Work"
        />
      ),
      placement: 'top',
      disableBeacon: true
    }
  ],
  [tourNameEmun.QANDA]: [
    {
      target: '[data-tour-id="mainView"]',
      placement: 'center',
      content: (
        <TourWithTextAndLink
          text="Welcome to the Build an Answer feature. Create accurate, consistent responses by leveraging your organization's collective knowledge."
          title="Build Comprehensive Responses"
        />
      ),
      disableBeacon: true
    },
    {
      target: '[data-tour-id="searchQuestions"]',
      placement: 'left',
      content: (
        <TourWithTextAndLink
          text="Quickly locate specific questions to focus your review. Save time by jumping directly to areas that need your expertise."
          title="Find Relevant Questions"
        />
      ),
      disableBeacon: true
    },
    {
      target: '[data-tour-id="viewSelectedSentences"]',
      placement: 'left',
      content: (
        <TourWithTextAndLink
          text="See the original question context to ensure accurate responses. Each question links back to source documents for full traceability.'"
          title="Review Source Material"
        />
      ),
      disableBeacon: true
    },
    {
      target: '[data-tour-id=""]',
      placement: 'left',
      content: <TourWithTextAndLink text="" title="" />,
      disableBeacon: true
    },
    {
      target: '[data-tour-id="answerPanel"]',
      placement: 'left',
      content: (
        <TourWithTextAndLink
          text="Review and refine suggested answers drawn from your organization's past responses and documentation"
          title="Leverage Collective Knowledge"
        />
      ),
      disableBeacon: true
    },
    {
      target: '[data-tour-id="publishButton"]',
      placement: 'left',
      content: (
        <TourWithTextAndLink
          text="Download responses for review, publish final answers, or share selected content with stakeholders to maintain alignment"
          title="Share Your Work"
        />
      ),
      disableBeacon: true
    },
    {
      target: '[data-tour-id="questionList"]',
      placement: 'right',
      content: (
        <TourWithTextAndLink
          text="Easily monitor which questions have been addressed. Radio buttons show completion status at a glance.'"
          title="Track Progress"
        />
      ),
      disableBeacon: true
    }
  ]
};

export const landingPages: { [key: string]: ILandingPage } = {
  [tourNameEmun.FOLDER]: {
    title: 'Welcome to Searchable Folders & Connected Documents',
    description:
      'KORU enables you to perform a single search across all project documents and automatically trace requirements and connect critical information.',
    steps: [
      {
        title: 'Organize Documents into Topic Folders',
        description:
          'Perform a single search across similar documents or topics.'
      },
      {
        title: 'Analyze & Trace Requirements',
        description:
          'Automatically categorize obligations and requirements and connect project documents by requirements or topic.'
      },
      {
        title: 'Collaborate on Document Reviews',
        description:
          'Create a feedback loop between key stakeholders who can research and share supporting information, add notes, redline text, and collaborate on important decisions.'
      },
      {
        title: 'Establish a Knowledge Base',
        description:
          'Retain institutional awareness of decisions made, compare obligations across executed contracts, and reuse authorized responses. Research and add supplemental information (such as specifications from technical manuals) to maintain quality expectations.'
      }
    ]
  },
  [tourNameEmun.PROJECT]: {
    title: 'Welcome to Projects',
    description:
      'KORU enables you to perform a single search across all project documents and automatically trace requirements and connect critical information.',
    steps: [
      {
        title: 'Organize Documents into Topic Folders',
        description:
          'Perform a single search across similar documents or topics.'
      },
      {
        title: 'Analyze & Trace Requirements',
        description:
          'Automatically categorize obligations and requirements and connect project documents by requirements or topic.'
      },
      {
        title: 'Collaborate on Document Reviews',
        description:
          'Create a feedback loop between key stakeholders who can research and share supporting information, add notes, redline text, and collaborate on important decisions.'
      },
      {
        title: 'Establish a Knowledge Base',
        description:
          'Retain institutional awareness of decisions made, compare obligations across executed contracts, and reuse authorized responses. Research and add supplemental information (such as specifications from technical manuals) to maintain quality expectations.'
      }
    ]
  },
  [tourNameEmun.DOCUMENT_VIEW]: {
    title:
      'Welcome to Your Documents Processing Hub. This is where you can analyze and manage your project documents.',
    description:
      'Folders help you to search across all project documents or just documents within single folders. These are example folders and you can delete and create your own or just create a single folder to store everything!',
    steps: [
      {
        title: 'Analyze Documents',
        description:
          'Upload and add documents to automatically analyze, discover, and categorize project obligations and requirements contained in project documents'
      },
      {
        title: 'Mark-up and Interact with Documents',
        description:
          'View a document highligted by categories of compliance requirements to create a plan of action, research and trace related requirements'
      },
      {
        title: 'Create a Knowledge Base',
        description:
          'Make documents searchable by projects as reference material.'
      }
    ]
  },
  [tourNameEmun.SEARCH]: {
    title: 'Welcome to the search page.',
    description:
      'Here you can perform searches accross various combinations of documents.',
    steps: [
      {
        title: 'Knowledge Base',
        description:
          'Specify with type of knowledge base documents you want to search, or leave blank to search all.'
      },
      {
        title: 'Q & A Map',
        description: 'Search existing responses to find similar answers.'
      }
    ]
  },
  [tourNameEmun.QANDA]: {
    title: 'Welcome to Build an Answer',
    description:
      "Create comprehensive, accurate responses by leveraging your organization's knowledge base and past Q&As. You'll be able to:",
    steps: [
      {
        title: 'Search and reference past answers',
        description: ' '
      },
      {
        title: 'Maintain consistency across responses',
        description: ' '
      },
      {
        title: 'Track completion progress',
        description: ' '
      },
      {
        title: 'Collaborate with your team',
        description: ' '
      }
    ]
  }
};
