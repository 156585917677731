import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Home } from 'lucide-react';
interface HomeProps {
  showBackButton: boolean;
}
export const BackAndHomeButtons: React.FC<HomeProps> = ({ showBackButton }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Goes back to the previous page
  };

  const handleHome = () => {
    navigate('/'); // Navigates to the home page
  };

  return (
    <div className="flex items-left justify-start gap-4 p-2">
      <button
        onClick={handleHome}
        className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
        aria-label="Home"
      >
        <Home className="w-3 h-3" />
      </button>
      {showBackButton && (
        <button
          onClick={handleBack}
          className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
          aria-label="Back"
        >
          <ArrowLeft className="w-3 h-3" />
        </button>
      )}
    </div>
  );
};
export const DocumentViewButtons: React.FC<HomeProps> = ({
  showBackButton
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Goes back to the previous page
  };

  const handleHome = () => {
    navigate('/'); // Navigates to the home page
  };

  return (
    <div className="flex items-left justify-start gap-4 p-0 ">
      <button
        onClick={handleHome}
        className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
        aria-label="Home"
      >
        <Home className="w-3 h-3" />
      </button>
      {showBackButton && (
        <button
          onClick={handleBack}
          className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
          aria-label="Back"
        >
          <ArrowLeft className="w-3 h-3" />
        </button>
      )}
    </div>
  );
};
