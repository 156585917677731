import * as React from 'react';
import Cards from '@cloudscape-design/components/cards';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { DocumentContext } from '../../../context/documentContext';
import { useContext, useEffect, useState } from 'react';
import Link from '@cloudscape-design/components/link';
import {
  ChatResponseSentence,
  IChatSummary,
  QandASentence,
  kbSentenceItem
} from '../../../models/interfaces';
import ShowMoreText from 'react-show-more-text';
import {
  Button,
  CopyToClipboard,
  ExpandableSection,
  TextContent
} from '@cloudscape-design/components';
import { KBIcon } from '../kbIcon';
import { getKBDocLinkAndOpen } from '../../../helpers/getAndOpenLink';
import { UserContext } from '../../../context/authContext';
import '../../Components/style/showMore.css';
import { HelpContext } from '../../../context/helpContext';

interface Props {
  kbresults: kbSentenceItem[];
  qaResults: QandASentence[];
  QAChecked: boolean;
  KBChecked: boolean;
  searchComplete: boolean;
}
export const FreeformKnowledgebase = (props: Props) => {
  const { kbresults, qaResults, QAChecked, KBChecked, searchComplete } = props;
  console.log('qaResults', qaResults);
  const [messages, setMessages] = useState<ChatResponseSentence[]>([]);
  const [topicSummary, setTopicSummary] = useState<IChatSummary>({});
  const {
    setFreeformeSelectedItemsKB,
    freeformSelectedItemsKB,
    freeformSelectedItemsQA,
    setFreeformeSelectedItemsQA
  } = useContext(DocumentContext);
  const { auth } = useContext(UserContext);
  const { showSearchComponents, setShowSearchComponents } =
    useContext(HelpContext);
  // this is a topic surray for all of the results

  const copyToClipboard = async (value: string) => {
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Blob([value], { type: 'text/plain' })
        })
      ]);
      console.log('Formatted text copied to clipboard.');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  return (
    <SpaceBetween direction="vertical" size="l" data-tour-id="searchResults">
      <>
        {(searchComplete && KBChecked) ||
        showSearchComponents['knowledgebase'] ? (
          <ExpandableSection
            headerText={`Knowledge Base Results - (${kbresults.length})`}
            defaultExpanded={KBChecked && !QAChecked}
          >
            <Cards
              onSelectionChange={({ detail }) =>
                setFreeformeSelectedItemsKB(detail?.selectedItems ?? [])
              }
              selectedItems={freeformSelectedItemsKB}
              cardDefinition={{
                sections: [
                  {
                    id: 'text',
                    header: 'Text',
                    content: (item) => item.text
                  },

                  {
                    id: 'Context',
                    header: 'Context',
                    content: (item) => {
                      return (
                        <ShowMoreText lines={3} anchorClass="showMoreClass">
                          {item.Context.text}
                        </ShowMoreText>
                      );
                    }
                  },
                  {
                    id: 'parameters',
                    header: 'Parameters',
                    content: (item) => item.All_Parameters.join(' ')
                  },
                  {
                    id: 'kbType',
                    header: 'Type',
                    content: (item) => item.knowledge_base_type
                  },
                  {
                    id: 'Similarity',
                    header: 'Similarity',
                    content: (item) =>
                      !isNaN(item.score * 100)
                        ? `${(item.score * 100).toFixed(2)} %`
                        : ''
                  },
                  {
                    id: 'link',
                    // header: 'Link',
                    content: (item) => (
                      <Button
                        onClick={() =>
                          getKBDocLinkAndOpen(
                            item.Document._id,
                            item.PageNumber,
                            auth
                          )
                        }
                        href=""
                        variant="link"
                      >
                        {item.Document.documentFileName}
                      </Button>
                    )
                  }
                ]
              }}
              cardsPerRow={[{ cards: 1 }]}
              items={kbresults}
              loadingText="Loading resources"
              selectionType="multi"
              trackBy="_id"
              visibleSections={[
                'text',
                'link',
                'parameters',
                'kbType',
                'Similarity',
                'Context'
              ]}
              empty={
                <Box
                  margin={{ vertical: 'xs' }}
                  textAlign="center"
                  color="inherit"
                >
                  <SpaceBetween size="m">
                    <br />
                    <b>No results found</b>
                  </SpaceBetween>
                </Box>
              }
            />
          </ExpandableSection>
        ) : null}
        {/* {KBChecked && kbresults.length === 0 && (
          <Box>No Knowledge Base results found</Box>
        )} */}
        {((searchComplete && QAChecked) || showSearchComponents['qanda']) && (
          <ExpandableSection
            headerText={`Q&A results - (${qaResults.length})`}
            defaultExpanded={!KBChecked && QAChecked}
          >
            <Cards
              onSelectionChange={({ detail }) =>
                setFreeformeSelectedItemsQA(detail?.selectedItems ?? [])
              }
              selectedItems={freeformSelectedItemsQA}
              cardDefinition={{
                sections: [
                  {
                    id: 'text',
                    header: 'Text',
                    content: (item) => item.text
                  },
                  {
                    id: 'answer',
                    header: 'Answer',
                    content: (item) => item.answer
                  }
                ]
              }}
              cardsPerRow={[{ cards: 1 }]}
              items={qaResults}
              loadingText="Loading resources"
              selectionType="multi"
              trackBy="_id"
              visibleSections={['text', 'link', 'answer']}
              empty={
                <Box
                  margin={{ vertical: 'xs' }}
                  textAlign="center"
                  color="inherit"
                >
                  <SpaceBetween size="m">
                    <br />
                    <b>No results found</b>
                  </SpaceBetween>
                </Box>
              }
            />
          </ExpandableSection>
        )}
        {/* {QAChecked && qaResults.length === 0 && <Box>No QA Results found</Box>} */}
      </>
    </SpaceBetween>
  );
};
