import {
  Box,
  Button,
  Cards,
  Container,
  FormField,
  Header,
  Input,
  LiveRegion,
  SpaceBetween
} from '@cloudscape-design/components';
import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
  useContext,
  useEffect,
  useState
} from 'react';
import { UserContext } from '../../../context/authContext';
import { createChatSummary } from '../../../helpers/createChatSummary';
import {
  ChatResponseSentence,
  IChatSummary,
  SummaryNames
} from '../../../models/interfaces';
import { getKBDocLinkAndOpen } from '../../../helpers/getAndOpenLink';
import ShowMoreText from 'react-show-more-text';
import { LoadingBar } from '@cloudscape-design/chat-components';
import { TopicSummary } from './topicSummary';

export const Chat = () => {
  const { token, auth } = useContext(UserContext);
  const [inputText, setInputText] = useState<string>('');
  const [messages, setMessages] = useState<ChatResponseSentence[]>([]);
  const [summary, setSummary] = useState<any>({});
  const [topicSummary, setTopicSummary] = useState<IChatSummary>({});
  const [jobComplete, setJobComplete] = useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const searchAndAwait = async () => {
    setMessages([]);
    setTopicSummary({});
    setSummary({});
    setJobComplete(false);
    setRequestLoading(true);
    const customHeaders = {
      Authorization: `Bearer ${token}`
    };
    const queryParams = new URLSearchParams(customHeaders).toString();
    const ws = new WebSocket(
      `wss://e99cr5vf84.execute-api.us-west-2.amazonaws.com/dev?${queryParams}`
    );
    ws.onopen = () => {
      console.log('WebSocket connection established');
      ws.send(
        JSON.stringify({
          action: 'sendMessage',
          message: [
            {
              text: inputText,
              _id: 'testId',
              messageToken: `${token}`
            }
          ]
        })
      );
    };
    // Handle incoming messages
    ws.onmessage = (event) => {
      console.log('Message received:', event.data);
      // if (event.data === 'done') {
      //   ws.close();
      // }

      const parsedData = JSON.parse(event.data);
      if (parsedData.type === 'content' && parsedData.sentence) {
        console.log('content result', parsedData);
        setMessages((prevState) => [...prevState, parsedData.sentence]);
        // console.log('chat responses JSON', JSON.stringify(parsedData.sentence));
      }
      if (parsedData.type === 'summary') {
        setSummary(parsedData);
      }
      if (parsedData.type === 'sentence-complete') {
        setJobComplete(true);
      }
    };
    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
    // Handle connection close
    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };
  };
  const handleChatSummary = () => {
    console.log('messages to summary chat', messages);
    const summaryResult = createChatSummary(messages);
    console.log('topic summary', JSON.stringify(summaryResult));
    setTopicSummary(summaryResult);
  };
  useEffect(() => {
    if (jobComplete) {
      handleChatSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobComplete]);
  return (
    <SpaceBetween size={'m'} direction="vertical">
      <Container
        header={
          <Header
            actions={
              <SpaceBetween size={'xxxs'}>
                <Button
                  onClick={() => {
                    setMessages([]);
                    searchAndAwait();
                  }}
                >
                  Search
                </Button>
              </SpaceBetween>
            }
          ></Header>
        }
      >
        <SpaceBetween size={'m'}>
          <FormField>
            <Input
              value={inputText}
              onChange={({ detail }) => setInputText(detail.value)}
            ></Input>
          </FormField>
          {requestLoading && (
            <SpaceBetween size={'m'} direction="vertical">
              {Object.keys(topicSummary).length > 0 ? (
                <TopicSummary topicSummary={topicSummary} />
              ) : (
                <LiveRegion>
                  <Box
                    margin={{ bottom: 'xs', left: 'l' }}
                    color="text-body-secondary"
                  >
                    Generating a response
                  </Box>
                  <LoadingBar variant="gen-ai" />
                </LiveRegion>
              )}

              {messages.length > 0 && (
                <Cards
                  cardDefinition={{
                    sections: [
                      {
                        id: 'text',
                        header: 'Text',
                        content: (item) => item.text
                      },

                      {
                        id: 'Context',
                        header: 'Context',
                        content: (item) => {
                          return (
                            <ShowMoreText lines={3} anchorClass="showMoreClass">
                              {item.Context.text}
                            </ShowMoreText>
                          );
                        }
                      },
                      {
                        id: 'parameters',
                        header: 'Parameters',
                        content: (item) => item.All_Parameters.join(' ')
                      },
                      {
                        id: 'kbType',
                        header: 'Type',
                        content: (item) => item.knowledge_base_type
                      },
                      // {
                      //   id: 'Similarity',
                      //   header: 'Similarity',
                      //   content: (item) =>
                      //     !isNaN(item.score * 100)
                      //       ? `${(item.score * 100).toFixed(2)} %`
                      //       : ''
                      // },
                      {
                        id: 'link',
                        // header: 'Link',
                        content: (item) => (
                          <Button
                            onClick={() =>
                              getKBDocLinkAndOpen(
                                item.Document._id,
                                item.PageNumber,
                                auth
                              )
                            }
                            href=""
                            variant="link"
                          >
                            {item.Document.documentFileName}
                          </Button>
                        )
                      }
                    ]
                  }}
                  cardsPerRow={[{ cards: 1 }]}
                  items={messages}
                  loadingText="Loading resources"
                  // selectionType="multi"
                  trackBy="_id"
                  visibleSections={[
                    'text',
                    'link',
                    'parameters',
                    'kbType',
                    'Similarity',
                    'Context'
                  ]}
                />
              )}
            </SpaceBetween>
          )}
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
