import {
  ExpandableSection,
  SpaceBetween,
  Textarea
} from '@cloudscape-design/components';
import { INotes } from '../../../../models/interfaces';

export const Notes = (props: {
  notes: INotes[];
  setNotesValue: (arg: string) => void;
  notesValue: string;
}) => {
  const { notes, setNotesValue, notesValue } = props;
  return (
    <ExpandableSection
      data-tour-id="notesSection"
      variant="container"
      // headerCounter={`${notes.length}`}
      headerCounter={notes?.length > 0 ? `${notes?.length}` : undefined}
      headerText={'Notes'}
    >
      <SpaceBetween size={'s'} direction="vertical">
        <div
          style={{
            overflowY: 'scroll',
            maxHeight: '20vh'
          }}
        >
          {notes?.map((note, index) => {
            const color = index % 2 > 0 ? 'lightGray' : 'none';
            const textColor =
              note?.text === 'Redlined' || note?.text === 'Removed Redline'
                ? 'Red'
                : 'black';
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: color,
                  borderRadius: '10px'
                }}
                key={index}
              >
                <p style={{ margin: '1% 1%', color: textColor }}>{note.text}</p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    borderTop: '1px solid black'
                  }}
                >
                  <p style={{ margin: '1% 1%' }}>{note?.owner}</p>
                  <p style={{ margin: '1% 1%' }}>
                    {new Date(note?.date).toLocaleDateString()}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <Textarea
          value={notesValue}
          rows={3}
          onChange={({ detail }) => setNotesValue(detail.value)}
        />
      </SpaceBetween>
    </ExpandableSection>
  );
};
